.button-premium {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  min-width: 160px;
  display: block;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
  &:focus,
  &:active.button-premium:focus {
    outline: none;
  }
  &:active,
  &.active {
    background-image: none;
  }

  &-primary {
    /*min-width: 150px;*/
   padding-top: 15px;
   padding-bottom: 15px;
   color: $aux-white;
   background-color: $clear-blue;
   filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
   background-repeat: repeat-x;
   border-color: transparent;
   border-radius: 30px;
   font-weight: bolder;

   &:hover,
   &:focus,
   &.focus,
   &:active,
   &.active {
    color: $aux-white;
    background-color: $blue0271A0;
    border-color: rgba(0, 0, 0, 0);
    }
    &:active,
    &.active {
      background-image: none;
    }
    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover,
    &.disabled:focus,
    &[disabled]:focus,
    &.disabled.focus,
    &[disabled].focus,
    &.disabled:active,
    &[disabled]:active,
    &.disabled.active,
    &[disabled].active {
      background-color: $clear-blue;
      border-color: transparent;
    }
    &:hover,
    &:focus {
      background-color: $blue15B7FD;
      background-position: 0 -15px;
    }
    &:active,
    &.active {
      background-color: $blue15B7FD;
      border-color: $blue20BAFD;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
      background-color: $blue15B7FD;
      background-image: none;
      opacity: 0.35;
    }
    &:hover {
      filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
      background-repeat: repeat-x;
    }
    &:hover:hover,
    &:hover:focus {
      background-color: $blue68BFE4;
      background-position: 0 -15px;
    }
    &:hover:active,
    &:hover.active {
      background-color: $blue68BFE4;
      border-color: $blue71C3E6;
    }
    &:hover.disabled,
    &:hover:disabled,
    &:hover[disabled] {
      background-color: $blue68BFE4;
      background-image: none;
    }
    &:active {
      filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
      background-repeat: repeat-x;
    }
    &:active:hover,
    &:active:focus {
      background-color: $blue01A1E5;
      background-position: 0 -15px;
    }
    &:active:active,
    &:active.active {
      background-color: $blue01A1E5;
      border-color: $blue01A8EF;
    }
    &:active.disabled, &:active:disabled, &:active[disabled] {
      background-color: $blue01A1E5;
      background-image: none;
    }
  }

  &-secondary {
    /*min-width: 150px;*/
    padding-top: 15px;
    padding-bottom: 15px;
    color: $aux-grey600;
    background-color: $aux-white;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: $aux-grey300;
    border-radius: 30px;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $aux-grey600;
      background-color: $black9;
      border-color: $black32;
    }
    &:active,
    &.active {
      background-image: none;
    }
    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover,
    &.disabled:focus,
    &[disabled]:focus,
    &.disabled.focus,
    &[disabled].focus,
    &.disabled:active,
    &[disabled]:active,
    &.disabled.active,
    &[disabled].active {
      background-color: $aux-white;
      border-color: $aux-grey300;
      color: $aux-grey300;
    }
    &:hover,
    &:focus {
      background-color: $aux-white;
      background-position: 0 -15px;
    }
    &:active,
    &.active {
      background-color: $aux-white;
      border-color: $aux-white;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
      background-color: $aux-white;
      background-image: none;
    }
    &:hover {
      color: $aux-white;
      background-color: $aux-grey600;
    }
    &:active {
      color: $aux-white;
      background-color: $black61;
    }
  }
}
#google, #microsoft {
  padding: .1rem;
  font-weight: bolder;

	&:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
   color: $aux-white;
   background-color: $blue68BFE4;
   border-color: rgba(0, 0, 0, 0);
  }
}
#thirdPartygoogle, #thirdPartymicrosoft {
  padding: .1rem;
  font-weight: 500;

	&:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
   color: $aux-white;
   background-color: $blue68BFE4;
   border-color: rgba(0, 0, 0, 0);
  }
}
