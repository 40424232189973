.color-dark-grey {
  color: #717171;
}
.digitallibrary h1 {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #002147;
}
.digitallibrary h3 {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #002147;
}
.digitallibrary h3 .title-underlined strong {
  box-shadow: inset -1px -30px 0px -22px #8cabd1;
}
.digitallibrary .helpcard-links {
  display: flex;
  position: relative;
  float: left;
  align-items: center;
  background-color: #fff;
  /*font-size: 14px;
  font-weight: 400;*/
  text-align: left;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #666;
  cursor: default;
  margin: 0 5px;
  padding: 25px 35px 25px 35px;
  min-width: 180px;
  max-width: 280px;
  height: 150px;
  border-radius: 4px;
}
.digitallibrary .helpcard-links span a {
  text-decoration: underline;
	/*color: #002147;*/
  color: #666;
  font-weight: 500;
  font-size: 14px;
	cursor: pointer;
}
.row-product-card {
  min-height: 229px;
}

.resourceList_icon {
  width: 5%;
  vertical-align: middle !important;
}

.resourceList_icon img {
  width: 100%;
}

.resourceList_name {
    vertical-align: middle !important;
    width: 85%;
}

.resourceList_button {
  width: 5%;
  vertical-align: middle !important;
}

@media (max-width: 992px) {
  .digitallibrary .helpcard-links {
    margin: 5px;
    padding: 20px 100px 20px 200px;    
    max-width: 650px;
    height: 130px;
    float: right;
    font-size: 14px;
  }
  .digitallibrary .helpcard-links span a {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .digitallibrary h3 {
    font-size: 1.15rem;
  }
  .digitallibrary h3 .title-underlined strong {
    box-shadow: inset -1px -28px 0px -22px #8cabd1;
  }
  .digitallibrary .helpcard-links {
    display: none;
    /*padding: 20px 25px 20px 25px;
    max-width: 350px;*/
  }
}