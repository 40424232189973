.schoolFinder {
  h2 {
    color: $aux-grey700;
    font-weight: $font-weight-bold;
  }

  p {
    color: $aux-grey600;
    font-size: $font-size-sm;
  }

  h6 {
    color: $aux-grey700;
    font-weight: $font-weight-bold;
  }

  &__label {
    color: $aux-grey600;
    font-size: $font-size-sm;
  }

  &__button {
    font-size: $font-size-sm;
    background: $clear-blue;
    border-radius: 2rem;
    border: none !important;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: $font-weight-bold;
  }

  &__button:hover {
    opacity: 0.5;
    background: $clear-blue;
  }

  &__button:focus {
    background: $clear-blue;
    box-shadow: none !important;
  }

  &__input {
    background: $aux-grey200;
  }

  &__input:focus {
    box-shadow: none !important;
    background: $aux-grey200;
  }

}