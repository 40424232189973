.container-gpa {
  & .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: $aux-white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: $aux-grey600;
    border-radius: 10px;
  }

  & .adopcion-recursos {
    & h2 {
      margin-bottom: 40px;
      font-size: 32px;
      color: $aux-grey900;
    }

    & .badge.number-adopcion {
      padding: 3px 11px;
      margin-left: 4px;
      font-weight: 400;
      vertical-align: top;
      background-color: $redF45531;
    }
  }
}