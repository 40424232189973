.pdnews {
  &-title {
    font-size: 2rem !important;
    line-height: 2rem;
    color: $aux-grey700;
    font-weight: $font-weight-bold;

    &-link {
      font-size: 0.9rem;
      font-weight: $font-weight-normal;
      transition: 0.4s;
      text-decoration: none;

      & a:hover {
        cursor: pointer;
        text-decoration:none;
      }
    }
  }
}
