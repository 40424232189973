h1.title {
  /*font-size: 2.5rem;*/
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #002147;
}
h4 {
  font-size: 1.5rem;
}
.h-inherit {
  height: inherit;
}
.w-inherit {
  width: inherit;
}
.font-color-black {
  color: #212121;
}
.font-color-grey {
  color: #666666;
}
.font-color-red {
  color: #ff0000;
}
.font-small-8 {
  font-size: .8rem;
}
.font-small-9 {
  font-size: .90rem;
}
.font-medium-1 {
  font-size: 1.2rem;
}
.font-medium-2 {
  font-size: 1.8rem;
}
.font-cross-out {
  text-decoration: line-through;
}
.border-top-blue {
  border-top: thick solid #8cabd1;
}
.border-bottom-blue {
  border-bottom: thick solid #8cabd1;
}
.padlock-icon {
  width: 60%;
}
.padlock-legend {
  padding-right: 10rem !important;
}

.order-confirmation .button-primary {
  font-size: .9rem;
  padding: 13px;
}

.purchase-result .subtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #666;
}
.purchase-result .description {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #666;
}
.purchase-result .description strong {
  font-weight: 700;
}
.purchase-result .button-primary {
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 992px) {
  h1.title {
    /*font-size: 2rem;*/
    font-size: 26px;
    line-height: 31px;
  }  
  h1.title .title-underlined strong {
    /*box-shadow: inset -1px -35px 0px -22px #8cabd1;*/
    box-shadow: inset -1px -32px 0px -22px #8cabd1;
  }
  .padlock-icon {
    width: 80%;
  }
  .padlock-legend {
    padding-right: 6rem !important;
  }
  .purchase-result .subtitle {
    font-size: 22px;
    line-height: 26px;
  }
  .purchase-result .description {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 576px) {
  /*h1.title {
    font-size: 1.6rem;
  }
  h1.title .title-underlined strong {
    box-shadow: inset -1px -30px 0px -22px #8cabd1;
  }*/
  .font-medium-2 {
    font-size: 1.5rem;
  }
  .border-bottom-blue-small {
    border-bottom: thick solid #8cabd1;
  }
  .padlock-icon {
    width: 115%;
  }
  .padlock-legend {
    padding-right: .8rem !important;
  }
  .purchase-result .subtitle {
    font-size: 20px;
    line-height: 24px;
  }
  .purchase-result .description {
    font-size: 20px;
    line-height: 24px;
  }
}