.container-gpa-responsablemodal {
  & .label-position {
    font-family: $font-family-sans-serif;
    color: $aux-grey600;
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .4px;
  }

  & select {
    font-size: 14px;
    background-color: $aux-grey200;
    font-family: $font-family-sans-serif;
    color: $aux-grey600;
    cursor: pointer;
  }

  & .select {
    border: 1px solid $black20;
    width: 100%;
    overflow: hidden;
    background: $aux-grey200 url("./img/arrowdown.gif") no-repeat 98% center;
    font-size: 14px;
    font-family: $font-family-sans-serif;
    color: $aux-grey600;
    cursor: pointer;
  }

  & .select select {
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  & .check-responsable {
    font-family: $font-family-button;
    font-size: 13px;
    color: $aux-grey600;
    min-height: 22px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    margin-top: 20px;
  }

  & .check-responsable a {
    text-decoration: none;
    color: $clear-blue;
    background-color: transparent;
  }

  & .check-responsable a:active,
  & .check-responsable a:hover {
    outline:0;
    text-decoration: none;
  }
  
  & .check-responsable a:hover {
     color: $cyan75;  
  }
  
  & .invalid-feedback {
    font-family: $font-family-sans-serif;
    font-size: 13px;
    color: $red;
  }

  & .error-position {
    font-family: $font-family-sans-serif;
    font-size: 13px;
    color: $red;
  }
  
  & .error-on {
    display: block
  }
  
  & .error-off {
    display: none
  }  
}