// My Library styles
.myLibrary {
  max-width: 100%;

  h1 {
    font-size: 2.7rem;
    color: $aux-grey700;
    font-weight: $font-weight-bold;

    .totalNumber {
      font-size: 0.85rem;
      padding: 0.1rem .8rem;
      vertical-align: text-top;
      background: $clear-blue;
      color: $white;
      border-radius: 0.8rem;
      display: inline-block;
    }

    .edit {
      font-size: 0.825rem;
      font-weight: $font-weight-normal;
      transition: 0.4s;
      text-decoration: none;
    }

  }

  &__img{
    width: 100%; 
    height: 100%;
  }

  &__modal {
    max-width: 60vw;

    &__sendInfo {
      width: 55%;
      border-radius: 1.5rem;
      font-weight: $font-weight-bold;
      background: #b50838;
      border: none;
    }

    &__sendInfo:hover {
      background: #b50838;
      opacity: .5;
    }

    &__sendInfo:focus {
      background: #b50838 !important;
      opacity: .5;
      border-color: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &__sendInfo:nth-child(1) {
      width: 40%;
      background: $aux-white;
      color: $aux-grey300;
      border: solid 1px $aux-grey300;
    }

    &__sendInfo:nth-child(1):hover {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border: solid 1px $aux-grey300 !important;
    }

    &__sendInfo:nth-child(1):focus {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border: solid 1px $aux-grey300 !important;
    }
  }

  &__thumbnail {
    left: -8%;
    top: 10%;
    width: 35%;
  }

  &__banner {
    background: #f6f6f6;
    display: block;
    margin: auto;
    height: 100px;
    padding: 5px;
    font-family: 'PT Serif',Georgia,"Times New Roman",Times,serif;
    font-size: 16px;
    line-height: 1.42857143;
  }

  &__remove {
    right: 0%;
    top: 10%;
    width: 35%;
    color: #0294d3 !important;
  }

  &__navTab {
    justify-content: end;
    border: none;

    &__link {
      color: $aux-grey600 !important;
      cursor: pointer;
      border: none !important;
      font-size: ($font-size-base * .875);
      font-weight: $font-weight-bold;
    }

    &__link:hover {
      border-bottom: solid 4px $black !important;
    }

    &__link.active {
      color: $black !important;
      border-bottom: solid 4px $black !important;
    }
  }
}
