.pdCourses {
  min-height: inherit;

  &__tab {
    width: 100%;
    background-color: $blue-pdcourses-tabs-background;
    display: inline-flex;
    vertical-align: middle;
  }

  &__item {
    /*width: 100%;*/
    font-size: .9rem;
    /*text-align: center;*/
    color: $aux-white;

    a {
      border: none !important;
      margin: 0 .5rem;
    }

    a:hover {
      border-bottom: solid .4rem $blue-pdcourses-tabs-border !important;
      cursor: pointer;
    }

    a.active {
      background: transparent !important;
      border-bottom: solid .4rem $blue-pdcourses-tabs-border !important;
      font-weight: $font-weight-bold;
      cursor: pointer;
    }
  }

  &__tab-content {
    min-height: inherit;
  }

  &__tab-pane {
    background-color: $aux-grey-background-pdcourses-tabs;
    min-height: inherit;
  }

  .pdTabAnnouncement {
    h5, h6 {
      font-weight: bold;
    }

    &__card {
      min-height: 24rem;
      height: auto;
      border-radius: 2rem;
      background-color: $aux-white;

      .card-text {
        color: $aux-grey-color-pdcourses-card;
        padding: inherit;

        ul {
          padding: 0;
        }
      }

      .card-footer {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        background-color: $aux-white;
        border: none;
        font-size: .9rem;

        a {
          text-decoration: underline;
        }
    
        a:hover {
          text-decoration: underline;
        }
    
        a.active {
          text-decoration: underline;
        }
      }
    }

    .col-cards-lg {
      display: none;
      @media (min-width: 992px) {
        display: block;
      }
    }
  }

  .jumbotron {
    &__pdbanner {
      margin-bottom: 0;
      margin-top: 0;
      padding: 1rem;
      background-color: $aux-white;

      .mycourses, .completed-courses {
        h4 {
          font-weight: bold;
        } 
      }

      .mycourses.registration, .completed-courses.registration {
        border: 2px solid rgba(0, 0, 0, 0.125);
        border-radius: .625rem;
        /*border-radius: 10px;*/
        /*box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);*/
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;

        background-color: $aux-white;
        padding: 1.5rem;

        img.miniature {
          width: inherit;
        }

        h5, h6 {
          font-weight: bold;
        }

        p {
          color: $aux-grey-color-pdcourses-card;
        }

        p.course-title {
          font-weight: bold;
          color: #212529;
          font-size: 1.1rem;
        }

        a.button-premium, button.button-premium {
          padding-left: 0;
          padding-right: 0;
          /*min-width: 8rem;
          width: 8rem;*/
          min-width: 6rem;
          width: inherit;
        }
      }

      .mycourses.no-registration, .completed-courses.no-registration {
        h4.label {
          @media (max-width: 576px) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .pdContact {
    h5, h6 {
      font-weight: bold;
    }

    &__card {
      /*min-height: 10rem;*/
      height: auto;
      border-radius: 2rem;
      background-color: $aux-white;

      .card-text {
        color: $aux-grey-color-pdcourses-card;

        a {
          text-decoration: underline;
        }
    
        a:hover {
          text-decoration: underline;
        }
    
        a.active {
          text-decoration: underline;
        }
      }
    }

    &__hours {
      color: $aux-grey-color-pdcourses-card;
    }
  }

  &__modal.error {
    .pdError {
      h6 {
        font-weight: bold;
      }
    }
  }

  &__modal.registration {
    width: 90% !important;
    max-width: 90% !important;
    @media (max-width: 576px) {
      /*width: 72% !important;
      max-width: 72% !important;*/
      width: 95% !important;
      max-width: 95% !important;
    }

    .pdCurrentRegistration {
      &__course {
        border-bottom: solid .15rem $aux-grey600 !important;
      }

      h4, h5, h6 {
        font-weight: bold;
      }
      
      h5, h6 {
        color: $aux-grey700;
      }
    }

    .pdCurrentRegistration.container {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    .pdRegistrationForm {

      form {
        color: $aux-grey700;
        font-size: 1rem;
      }

      label.label-course {
        font-size: 1.3rem;
        font-weight: bold;
        @media (max-width: 576px) {
          font-size: 1rem;
        }
      }

      select#course.selected-default.form-control, select#course .selected-default {
        font-style: italic;
        color: $aux-grey700;
        @media (max-width: 576px) {
          font-size: .8rem;
        }
      }

      select#course.selected-course.form-control, select#course .selected-course {
        font-weight: bold;
        color: $aux-grey900;
        @media (max-width: 576px) {
          font-size: .8rem;
        }
      }

      select#course.disabled-course.form-control, select#course .disabled-course {
        font-weight: bold;
        color: $aux-grey600;
        @media (max-width: 576px) {
          font-size: .8rem;
        }
      }

      label.custom-control-label {
        text-align: justify;
        font-size: .9rem;
        @media (max-width: 576px) {
          font-size: .8rem;
        }
      }

      label.label-birthday {
        font-weight: bold;
      }

      input#birthday.form-control {
        width: 16rem;
      }
    }

    .pdRegistrationOk {
      &__course {
        border-bottom: solid .15rem $aux-grey600 !important;
      }

      h4, h6 {
        font-weight: bold;
      }
      
      h6 {
        color: $aux-grey700;
      }
    }

    .pdRegistrationOk.container {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .pdCompletedCatalogue {
    img.miniature {
      width: inherit;
    }

    h4, h5 {
      font-weight: bold;
      color: $aux-grey700;
    }

    @media (min-width: 768px) {
      .button-premium-primary {
        width: fit-content;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
}