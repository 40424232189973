.cookieBanner {
  padding: 1.5rem 0;
  text-align: center;
  background: #333;

  .text {
    color: #fff;
    margin: 0 0 1rem 0;
  }

  .buttons {
    & > *:first-child {
      margin-right: 1rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    // svg {
      // fill: #333; // TODO: This shouldn't be needed
    // }
  }
}

@media only screen and (min-width: 991px) {
  .cookieBanner {
    :global(.col) {
      flex-direction: row;
      text-align: left;
    }

    .text {
      margin: 0 2rem 0 0;
    }

    .buttons {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }
  }
}

#cookieBannerInfo {
  width: 200px;
}
#cookieBannerClose {
  width: 150px;
}
