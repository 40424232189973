.notification-bell {
    position: relative;
    display: inline-block;
}

.notification-bell .notification-badge {
    position: absolute;
    bottom: 2px;
    right: -2px;
    padding: 4px 4px;
    border-radius: 50%;
    background: #b50837;
    border: .5px solid $aux-greyBlue;
}

.notifications-page {
    color: $aux-grey700;

    & > h1 { font-size: 28px; }
    & > h2 { font-size: 22px; }
    & > div { font-size: 14px; }
    & > hr { border: 0.8px solid $aux-grey700; }
    & a {
        font-size: 16px;
        color: $aux-grey700;
    }
}
