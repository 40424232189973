.title-carousel {
    &-modal {
        max-width:1104px;
        @include media-breakpoint-up(sm) {
            max-width: 572.3px;
        }
        @include media-breakpoint-up(md) {
            max-width: 664px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 878px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 1104px;
        }

        & container, & row, & col {
            padding: 0;
        }
    }

    &-modal > .modal-content {
        border-radius: 20px;
        overflow: hidden;
        height: 25vw;
        @include media-breakpoint-up(sm) {
            height: 344.8px;
            width: 572.3px;
        }
        @include media-breakpoint-up(md) {
            height: 400px;
            width: 664px;
        }
        @include media-breakpoint-up(lg) {
            height: 530px;
            width: 878px;
        }
        @include media-breakpoint-up(xl) {
            height: 666px;
            width: 1104px;
        }
    }

    &-item {
        padding-left: 0;
        padding-right: 0;
        height: 25vw;
        @include media-breakpoint-up(sm) {
            height: 344.8px;
            width: 572.3px;
        }
        @include media-breakpoint-up(md) {
            height: 400px;
            width: 664px;
        }
        @include media-breakpoint-up(lg) {
            height: 530px;
            width: 878px;
        }
        @include media-breakpoint-up(xl) {
            height: 666px;
            width: 1104px;
        }

        &-title {
            font-size: 16px;
            font-weight: bold;
            color: #707070;
            @include media-breakpoint-up(sm) {
                font-size: 16px;
                margin-top: 37.9px;
                margin-left: 37.1px;
            }
            @include media-breakpoint-up(md) {
                font-size: 20px;
                margin-top: 47px;
                margin-left: 43px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 22px;
                margin-top: 76px;
                margin-left: 57px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 30px;
                margin-top: 129px;
                margin-left: 72px;
            }
        }
        &-col-body {
            color: $aux_grey700;

            height: 25vw;
            @include media-breakpoint-up(sm) { height: 344.8px; }
            @include media-breakpoint-up(md) { height: 400px; }
            @include media-breakpoint-up(lg) { height: 530px; }
            @include media-breakpoint-up(xl) { height: 666px; }

            font-size: 16px;

            &-body {
                @include media-breakpoint-up(sm) {
                    margin-top: 17.2px;
                    margin-left: 37.1px;
                    height: 154.3px;
                    width: 218.1px;
                    font-size: 12px;
                    line-height: 18px;
                }
                @include media-breakpoint-up(md) {
                    margin-top: 20px;
                    margin-left: 43px;
                    height: 171px;
                    width: 253px;
                    font-size: 14px;
                    line-height: 22px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 26px;
                    margin-left: 57px;
                    height: 217px;
                    width: 335px;
                    font-size: 18px;
                    line-height: 26px;
                }
                @include media-breakpoint-up(xl) {
                    margin-top: 36px;
                    margin-left: 72px;
                    height: 225px;
                    width: 421px;
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }

        &-col-image {
            height: 25vw;
            @include media-breakpoint-up(sm) { height: 344.8px; }
            @include media-breakpoint-up(md) { height: 400px; }
            @include media-breakpoint-up(lg) { height: 530px; }
            @include media-breakpoint-up(xl) { height: 666px; }

            & > img,
            & > video {
                object-fit: cover;
            }
        }
    }

    &-close {
        right: 20px;
        top: 20px;
        z-index: 2;
        @include media-breakpoint-up(sm) { right: 18.5px; top: 18.5px; }
        @include media-breakpoint-up(md) { right: 21.5px; top: 21.5px; }
        @include media-breakpoint-up(lg) { right: 29px; top: 29px; }
        @include media-breakpoint-up(xl) { right: 35px; top: 35px; }

        &.button-close img {
            @include media-breakpoint-up(sm) { width: 20px; height: 20px; }
            @include media-breakpoint-up(md) { width: 22.1px; height: 22.1px; }
            @include media-breakpoint-up(lg) { width: 29.3px; height: 29.3px; }
            @include media-breakpoint-up(xl) { width: 36.8px; height: 36.8px; }
        }
    }

    &-row-controls {
        @include media-breakpoint-up(sm) {
            margin-top: 46.6px;
            margin-left: 37.1px;
            padding-left: 3.4px;
            height: 27.6px;
            width: 218.1px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 59px;
            margin-left: 43px;
            padding-left: 4px;
            height: 32px;
            width: 253px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 74px;
            margin-left: 57px;
            padding-left: 5px;
            height: 42px;
            width: 365px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: 107px;
            margin-left: 72px;
            padding-left: 6px;
            height: 53px;
            width: 465px;
        }

        &-mini-buttons,
        &-button-large {
            display: inline-block;
            @include media-breakpoint-up(sm) {
                height: 27.6px;
                width: 75.8px;
                font-size: 10px;
            }
            @include media-breakpoint-up(md) {
                height: 32px;
                width: 88px;
                font-size: 12px;
            }
            @include media-breakpoint-up(lg) {
                height: 42px;
                width: 96px;
                font-size: 14px;
            }
            @include media-breakpoint-up(xl) {
                height: 53px;
                width: 121px;
                font-size: 14px;
            }
        }

        &-mini-buttons > button:first-child {
            margin-right: 0;
            @include media-breakpoint-up(sm) { margin-right: 10px; }
            @include media-breakpoint-up(md) { margin-right: 12px; }
            @include media-breakpoint-up(lg) { margin-right: 12px; }
            @include media-breakpoint-up(xl) { margin-right: 15px; }
        }

        &-indicators {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-up(sm) {
                height: 27.6px;
                width: 138.9px;
                font-size: 10px;
            }
            @include media-breakpoint-up(md) {
                height: 32px;
                width: 161px;
                font-size: 12px;
            }
            @include media-breakpoint-up(lg) {
                height: 42px;
                width: 264px;
                font-size: 14px;
            }
            @include media-breakpoint-up(xl) {
                height: 53px;
                width: 338px;
                font-size: 14px;
            }
        }

        & * .button-premium {
            display: inline-block;
            font-weight: normal !important;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            min-width: 0;
            height: 100%;
            @include media-breakpoint-up(sm) {
                padding: 5px;
                height: 27.6px;
                width: 75.8px;
                font-size: 10px;
            }
            @include media-breakpoint-up(md) {
                padding: 5px;
                height: 32px;
                width: 88px;
                font-size: 12px;
            }
            @include media-breakpoint-up(lg) {
                padding: 10px;
                height: 42px;
                width: 96px;
                font-size: 14px;
            }
            @include media-breakpoint-up(xl) {
                padding: 10px;
                height: 53px;
                width: 121px;
                font-size: 14px;
            }
        }
    }

    &-row-bottom {
        height: 15%;
        color: $aux_grey700;
        text-decoration-color: $aux_grey700;
        text-decoration: underline;
        @include media-breakpoint-up(sm) {
            margin-left: 37.1px;
            padding-left: 3.4px;
            height: 40px;
            width: 218.1px;
            font-size: 12px;
        }
        @include media-breakpoint-up(md) {
            margin-left: 43px;
            padding-left: 4px;
            height: 45px;
            width: 253px;
            font-size: 14px;
        }
        @include media-breakpoint-up(lg) {
            margin-left: 57px;
            padding-left: 5px;
            height: 67px;
            width: 335px;
            font-size: 16px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: 72px;
            padding-left: 6px;
            height: 86px;
            width: 421px;
            font-size: 16px;
        }
    }

    &-button-mini {
        display: inline-block;

        &.button-rounded {
           color: $aux-white;
           background-color: $clear-blue;
           filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
           background-repeat: repeat-x;
           border-color: transparent;
           font-weight: bolder;

           &:hover,
           &:focus,
           &.focus,
           &:active,
           &.active {
             color: $aux-white;
             background-color: $blue0271A0;
             border-color: rgba(0, 0, 0, 0);
           }
           &:active,
           &.active {
             background-image: none;
           }
           &.disabled,
           &[disabled],
           &.disabled:hover,
           &[disabled]:hover,
           &.disabled:focus,
           &[disabled]:focus,
           &.disabled.focus,
           &[disabled].focus,
           &.disabled:active,
           &[disabled]:active,
           &.disabled.active,
           &[disabled].active {
             background-color: $clear-blue;
             border-color: transparent;
           }
           &:hover,
           &:focus {
             background-color: $blue15B7FD;
             background-position: 0 -15px;
           }
           &:active,
           &.active {
             background-color: $blue15B7FD;
             border-color: $blue20BAFD;
           }
           &.disabled,
           &:disabled,
           &[disabled] {
             background-color: $blue15B7FD;
             background-image: none;
             opacity: 0.35;
           }
           &:hover {
             filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
             background-repeat: repeat-x;
           }
           &:hover:hover,
           &:hover:focus {
             background-color: $blue68BFE4;
             background-position: 0 -15px;
           }
           &:hover:active,
           &:hover.active {
             background-color: $blue68BFE4;
             border-color: $blue71C3E6;
           }
           &:hover.disabled,
           &:hover:disabled,
           &:hover[disabled] {
             background-color: $blue68BFE4;
             background-image: none;
           }
           &:active {
             filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
             background-repeat: repeat-x;
           }
           &:active:hover,
           &:active:focus {
             background-color: $blue01A1E5;
             background-position: 0 -15px;
           }
           &:active:active,
           &:active.active {
             background-color: $blue01A1E5;
             border-color: $blue01A8EF;
           }
           &:active.disabled, &:active:disabled, &:active[disabled] {
             background-color: $blue01A1E5;
             background-image: none;
           }
        }
    }

    &-indicator {
        display: flex;
        @include media-breakpoint-up(sm) {
            height: 6.8px;
            width: 6.8px;
        }
        @include media-breakpoint-up(md) {
            height: 8px;
            width: 8px;
        }
        @include media-breakpoint-up(lg) {
            height: 12px;
            width: 12px;
        }
        @include media-breakpoint-up(xl) {
            height: 16px;
            width: 16px;
        }
        &-circle {
            margin: auto;
            background-color: #cccccc;
            border-radius: 50%;
            border: 0;
            @include media-breakpoint-up(sm) {
                height: 3.4px;
                width: 3.4px;
            }
            @include media-breakpoint-up(md) {
                height: 4px;
                width: 4px;
            }
            @include media-breakpoint-up(lg) {
                height: 6px;
                width: 6px;
            }
            @include media-breakpoint-up(xl) {
                height: 8px;
                width: 8px;
            }

            &-active {
                background-color: #3e3e3e;
            }
        }
    }
}
