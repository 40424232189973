.arrow-steps .step {
  display: flex;
  position: relative;
  /*float: left;*/
  float: right;
  align-items: center;
  background-color: #fff;
  /*font-size: 14px;
  font-weight: 500;*/
  text-align: left;
  /*color: #212121;*/
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  cursor: default;
  margin: 0 5px;
  padding: 25px 35px 25px 35px;
  min-width: 180px;
  max-width: 350px;
  height: 150px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  right: -17px;
  z-index: 2;
  width: 0;
  height: 0;
  border-top: 75px solid transparent;
  border-bottom: 75px solid transparent;
  border-left: 17px solid #fff;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  z-index: 0;
  right: auto;
  left: 0;
  border-left: 17px solid #f2f5f7;
}

.arrow-steps .step:first-child:before,
.arrow-steps .step:last-child:after {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span strong {
  font-weight: 950;
}

.arrow-steps .step-index {
  display: flex;
  align-items: center;
	align-content: center;
  z-index: 2;
  background-color: #8CABD1;
  margin: 0 auto;
  margin-right: 10px;
  padding: 20px;
  width: 50px;
	height: 50px;
	border-radius: 50%;
}

.arrow-steps .step-index > div {
  display: flex;
	line-height: 0;
  align-items: center;
  text-align: center;
  font-weight: 800;
	font-size: 20px;
  color: #fff;
}

@media (max-width: 1200px) {
  .arrow-steps .step {
    padding: 20px;
    max-width: 292px;
  }
}

@media (max-width: 992px) {
  .arrow-steps .step {
    margin: 5px;
    padding: 20px 30px 20px 30px;
    max-width: 320px;
    height: 130px;
    font-size: 14px;
  }
  
  .arrow-steps .step:after,
  .arrow-steps .step:before {
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
  }
}

@media (max-width: 576px) {
  .arrow-steps .step {
    max-width: 350px;
    font-size: 12px;
  }
}