// .modal-school-modal {
    // .modal-title {
        // text-align: center;
        // font-weight: bold;
        // margin-top: 2rem;
        // margin-bottom: 1rem;
    // }
    // h5 {
        // font-size: 18px;
        // font-weight: normal;
        // text-align: justify;
    // }
    // p.modal-school-subtitle {
        // font-size: 16px;
    // }
    // .error {
        // color: #dc3545;
    // }
// }

.guidance {
  .modal-title {
    width: 100%;
  }
}
