#footer {
  margin-top: 0;
  padding-top: 35px;
  padding-bottom: 35px;
  font-family: $font-family-sans-serif;
  font-size: 12px;
  color: $black33;
  background-color: $black74;
  border-right: 1px solid $aux-white;


  & p {
    padding: 8px 0 8px 13px;
    margin-bottom: 20px;
    font-weight: 700;
    color: $black58;
  }
  & ul > li > a {
    padding: 8px 0 8px 13px;
    font-weight: 700;
    line-height: 2.50em;
    color: $aux-grey600;
    text-decoration: none;
  }
  & ul > li > a:active {
    color: $black60;
  }
  & ul > li > a:hover {
    color: $aux-grey300;
  }
  & ul > li > a.principal-link {
    color: $aux-white;
  }
  & ul > li > a.principal-link:active {
    color: $black60;
  }
  & ul > li > a.principal-link:hover {
    color: $black50;
  }
  & ul > li > a.icon-rrss {
    padding: 8px 0 8px 30px;
    margin-left: 13px;
  }
  & .oxford-link-left {
    border-left: 1px solid $aux-grey600;
  }
  & .oxford-link-right {
    border-right: 1px solid $aux-grey600;
  }
  & .oxford-legal-link {
    border-right: 1px solid $aux-grey600;
  }
  & h2 {
    margin-top: 0;
    color: $aux-white;
  }
  & ul {
    margin: 0;
  }

  /* MBQ OCT 2023 UPGRADE FOOTER */
  & .block-one {
    & ul {
      display: flex;
      flex-flow: wrap;
      margin: 0 13px;

      @media (min-width: 1200px) {
        width: 100%;
        margin: 0;

        & li {
          text-align: center;
        }

        & li:not(:last-child) {
          border-right: 1px solid #aaa;
        }

        & li.w15 {
          width: 15%;
        }

        & li.w20 {
          width: 20%;
        }

        & li.w25 {
          width: 25%;
        }
      }

      & li > a {
        padding: 0;
      }

      & li > span.separator {
        padding: 10px;

        @media (min-width: 1200px) {
          display: none;
        }
      }
    }
  }

  & .block-two {
    @media (max-width: 576px) {
      padding-top: 35px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      & p, & ul > li > a {
        padding: 8px 0 8px 0;
      }
    }

    @media (min-width: 1200px) {
      & p, & ul > li > a {
        padding: 8px 0 8px 2px;
      }
    }
  }

  & .block-three {
    @media (max-width: 576px) {
      padding-top: 35px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      & p, & ul > li > a {
        padding: 8px 0 8px 10px;
      }
    }
  }  
  /* MBQ OCT 2023 UPGRADE FOOTER */

  /* ********************** */
  /*ICONS FOOTER */
  /* MBQ OCT 2023 UPGRADE FOOTER */
  /*
  .icon-rrss {
    margin-left: 30px;
    background-image: url("./img/icon_rrss.jpg");
    background-repeat: no-repeat;
    
    &.youtube {
      background-position: -6px 3px;
    }
    &.cart {
      background-position: -6px -21px;
    }
    &.facebook {
      background-position: -6px -44px;
    }
    &.twitter {
      background-position: -6px -69px;
    }
  }
  */
  .icon-rrss {
    margin-left: 30px;    
    background-image: url("./img/icon_rrss_v2.jpg");    
    background-repeat: no-repeat;
    
    &.youtube {
      background-position: -6px 3px;
      display: none;
    }

    &.cart {
      background-position: -6px -21px;
      display: none;
    }

    &.facebook {
      background-position: -6px -44px;
      display: none;
    }

    &.twitter {
      background-position: -6px -69px;
      display: none;
    }

    &.all-rrss {
      background-position: -6px -96px;
      padding: 8px 0 8px 90px !important;
    }
  }
  /* MBQ OCT 2023 UPGRADE FOOTER */

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    margin-top: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
}