.homepage {
  &-header {

    .jumbotron {
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;

      padding: 3rem 1rem 1rem 1rem;
      text-align: center;

      @include media-breakpoint-up(sm) { 
        padding: 5rem 0 1rem 0;
        text-align: left;
      }
      @include media-breakpoint-up(md) { 
        padding: 5rem 0 1rem 0;
      }
      @include media-breakpoint-up(lg) { 
        padding: 10rem 0 1rem 0;
      }


      h1 {
        font-weight: bolder;
        text-shadow: 4px 3px 0 #7A7A7A;
      }

      .lead {
        font-weight: 600;
        margin-bottom: 3rem;
      }

      .button-premium {
          font-size: 1.25rem;
          line-height: 1.5;
          margin-bottom: 3rem;
      }

    }

  }
  &-content {
    color:black;

    &-item {
      margin: 5rem auto;
      padding-bottom: 5rem;
      border-bottom: 1px solid gray;
      text-align: center;

      &:last-child { /* Remove separator line for the last element */
        border-bottom: none;
      }

      @include media-breakpoint-up(sm) { 
        text-align: left;
      }
      .leadsection {

        display: flex;
        justify-content: center;

        @include media-breakpoint-up(sm) { 
          justify-content: flex-end;
        }

        h2 {
          font-size: 1.5rem;
          font-weight: bolder;
          margin-top: 2rem;
  
          @include media-breakpoint-up(lg) { 
            margin-top: 0;
          }
        }
  
        .lead {
          margin-bottom: 2rem;
        }
      }
    }

    &-item:nth-child(odd) { /* Different style for odd elements */
      flex-direction: row-reverse;
      .leadsection {
        @include media-breakpoint-up(lg) { 
          justify-content: flex-start;
        }
      }
    }
  }
}