.mydelegates {
    &-modal .modal-content {
        border-radius: 20px;
        overflow: hidden;
    }

    &-modal .modal-header {
        background-color: $dark-blue;
        color: $white;
        border-bottom: 0;

        & button {
            color: $white;
        }
    }

    &-close-button {
        background-color: #2f95cc;
        color: $white;
    }

    &-name {
        color: $aux-grey600;
        font-weight: 700;
        size: 2em;
    }

    &-data {
        color: $aux-grey600;
    }

    &-close {
        right: 16px;
        top: 16px;
        z-index: 2;
        @include media-breakpoint-up(sm) { right: 16px; top: 16px; }
        @include media-breakpoint-up(md) { right: 16px; top: 16px; }
        @include media-breakpoint-up(lg) { right: 18px; top: 18px; }
        @include media-breakpoint-up(xl) { right: 18px; top: 18px; }

        &.button-close img {
            width: 25px;
            height: 25px;
        }
    }
}
