// EditLibrary styles
.editLibrary {
  max-width: 100%;
  background: $aux-grey200;

  &__colTitle {
    font-size: $font-size-sm;
    color: #6b7074;
    padding: 0.2rem 0.4rem !important;
    font-weight: $font-weight-bold;
  }

  &__border {        
    border: .2rem solid #0296d6;    
    span{
      color: #0294d3 !important;
      font-size: 250%;
    }    
  }

  &__check {
    top: 0;
    z-index: 5;
  }

  &__tab__border{
    border-color: $aux-grey600 !important;
  }

  &__schema {
    margin-right: inherit;
  }

  &__catOnly {
    background: $aux-white;
    border-radius: 0.5rem;
  }

  &__col:hover {
    cursor: pointer;
  }

  &__listContainer {
    background: $aux-white;
  }

  &__tab {
    width: 100%;

    &__item {
      width: 100%;
      font-size: 1.2rem;
      text-align: center;
      color: $aux-grey600;

      a {
        border: none !important;
        margin: 0 .5rem;
      }

      a:hover {
        border-bottom: solid .4rem $purple !important;
        cursor: pointer;
      }

      a.active {
        background: transparent !important;
        border-bottom: solid .4rem $purple !important;
        color: $aux-black;
        font-weight: $font-weight-bold;
        cursor: pointer;
      }
    }
  }

  &__navTab {
    justify-content: end;
    border: none;

    &__link {
        color: $aux-grey600 !important;
        cursor: pointer;
        border: none !important;
        font-size: ($font-size-base * .875);
        font-weight: $font-weight-bold;
    }
    &__link:hover {
        border-bottom: solid 4px $black !important;
    }
    &__link.active {
        color: $black !important;
        background-color: transparent !important;
        border-bottom: solid 4px $black !important;
    }
  }
}

.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.edit__library {
  button {
    padding: 0.8rem 2.5rem;
    border-radius: 1.5rem;
    border-width: 0.1rem;
    color: $aux_white !important;
  }

  :disabled  {
    background: #0294d3;
    opacity: 0.25;
    font-weight: $font-weight-normal;
    border: none;
  }

  :hover[disabled] {
    background: #0294d3;
    opacity: 0.25;
    font-weight: $font-weight-normal;
    border: none;
  }

  &__accept {
    background: #0294d3;
    font-weight: $font-weight-normal;
    border: none;
  }

  &__accept:hover {
    opacity: 0.75;
    background: #0294d3;
  }

  &__accept:focus {
    opacity: 1;
    background: #0294d3 !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &__thumbnail {
    cursor: pointer;
  }

  &__h2 {
    font-weight: $font-weight-bold;
    color: #6b7074;
  }

  &__form{
    font-size: $font-size-sm;
    span{
      margin-right: 1.5rem;
    }
  } 

}

/* MBQ PROMOTIONS */
.myLibrary {
  @include media-breakpoint-up(xl) {
    .col-xl-1 {
      flex: 0 0 12%;
      max-width: 12%;
    }
  }

  &__img-edit {
    width: 100%;
    height: 100%;
    border: .2rem solid transparent;
  }

  &__img-edit.border-solid {
    border: .2rem solid #0296d6;
  }

  &__thumbnail-edit {
    left: 10%;
    top: 5%;
    width: 35%;
  }

  &__remove-edit {
    left: 75%;
    top: 5%;
    width: 35%;
    color: #0294d3 !important;
  }

  .promotion-sticker-edit {
    position: absolute;
    top: 85%;
    left: 10%;
    background-color: $orange-promotion-sticker;
    color: $aux-white;
    font-weight: $font-weight-bold;
    padding: 0.13rem 0.6rem 0.13rem 0.6rem;
    border-radius: 0.5rem 0.13rem 0.5rem 0.13rem;
    font-size: 0.8rem;
  }
}
/* MBQ PROMOTIONS */
