.coupons-modal {
    max-width: 611px;
    top: 300px;
}
@media (max-width: 767.98px) {
    .coupons-modal {
        max-width: 527px;
    }
}

@media (max-width: 575.98px) {
    .coupons-modal {
        max-width: 96%;
    }
}

.coupons-modal > .modal-content {
    border-radius: 15px;
    overflow: hidden;
    min-height: 290px;

}
@media (max-width: 767.98px) {
    .coupons-modal > .modal-content {
        width: 527px;
        min-height: 262px;
    }
}

@media (max-width: 575.98px) {
    .coupons-modal > .modal-content {
        width: 96%;
        min-height: 262px;
    }
}

.coupons-modal .modal-header {
    padding-top: 36px;
    border: 0;
}

.coupons-modal h5.modal-title {
    font-size: 24px;
    color: #000000;
    line-height: 28.8px;
    font-weight: 700;
    text-align: center;
    margin: auto;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    .coupons-modal h5.modal-title {
        font-size: 20px;
        line-height: 24px;
    }
}
@media (max-width: 767.98px) {
    .coupons-modal h5.modal-title {
        font-size: 18px;
        line-height: 22px;
    }
}

.coupons-modal .modal-body {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    font-style: normal;
    padding-left: 65px;
    padding-right: 65px;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    .coupons-modal .modal-body {
        font-size: 16px;
    }
}
@media (max-width: 767.98px) {
    .coupons-modal .modal-body {
        font-size: 14px;
    }
}

.coupons-modal .modal-footer {
    border: 0;
}

.coupons-modal .modal-footer > button {
    margin: auto;
}

