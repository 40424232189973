.container-gpa {
  font-family: $font-family-button;
  font-size: 16px;
  line-height: 1.42857143;
  color: $aux-grey600;
  background: $aux-grey200;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: transparent solid 0.1px;
  min-height: inherit;  

  & .container {
    @include media-breakpoint-up(md) { width: 1054px; }
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-top:  2rem; 
  }  

  & p {
    margin: 0 0 11px;
    display: block !important;
  }

  & h6, & .h6 {
    font-size: 14px;
  }

  & h4, & .h4, & h5, & .h5, & h6, & .h6 {
    margin-top: 11px;
    margin-bottom: 11px;
  }

  & h1, & h2, & h3, & h4, & h5, & h6, & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: $aux-grey700;
    display: block !important;
  }

  & .well {
    border-color: $aux-grey600;
    -webkit-box-shadow: inset 0 1px 3px rgba(0,0,0,.05),0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 3px rgba(0,0,0,.05),0 1px 0 rgba(255,255,255,.1);
  }

  & .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: $black4;
    border: 1px solid $black11;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  }

  & .adopcion-recursos {
    & h3 {
      padding-bottom: 5px;
      font-size: 26px;
      color: $aux-grey900;
      border-bottom: 2px solid $aux-grey600;
    }
    
    & .well.well-cards-material p.small {
      font-size: 12px;
      line-height: 15px;
    }

    & .well.well-cards-adopcion .image-recurso {
      border-radius: 5px;
    }

    & .well.well-cards-material .image-recurso {
      margin: 0;
      border-radius: 5px;
    }

    & .cargo-titulo {
      @include media-breakpoint-up(md) { float: right; }      
    }
  }

  & .img-responsive {
    margin-right: auto;
    margin-left: auto;
  }

  & .img-responsive, & .thumbnail > img, & .thumbnail a > img, & .carousel-inner > .item > img, & .carousel-inner > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  & img {
    vertical-align: middle;
    border: 0;
  }
}