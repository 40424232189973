/* MBQ Cambio .resources por .products para que no haya interferencias de estilos de otros componentes */
// Resources styles
.products {

  &__demo {
    z-index: 15;
    top: 1.5rem;
    left: -0.5rem;
    width: 4.5rem;
  }

  &__card {
    height: 0;
    overflow: hidden;
    /*padding-top: 140%;*/
    background: $aux-white;

    &__inside {
      top: 0;
      left: 0;

      &__legend {
        font-weight: $font-weight-bold;
        font-size: .9rem;


        &__absolute {
          position: absolute;
          bottom: 0;
          z-index: 20;
          width: 100%;
        }

        &__expiry {
          font-weight: $font-weight-normal;
          font-size: .8rem;

          &__student {
            background: $aux-chinaIvory !important;

            button {
              border-radius: 1rem;
              font-size: 0.8rem;
              background: $aux-grey700;
              border: none;
              width: 100%;
              color: $aux-black;
            }

            button:hover {
              background: $aux-grey700;
              color: $aux-black;

              span {
                opacity: .5;
              }
            }

            button:focus {
              box-shadow: none !important;
              background: $aux-grey700 !important;

              span {
                opacity: .5;
              }
            }
          }

          &__code {
            background: $aux-chinaIvory !important;
            border: none;
            color: $aux-black;
            font-size: .7rem;
            font-weight: $font-weight-bold;
            letter-spacing: 0.15em;
          }

          &__code:hover {
            color: $aux-black !important;
          }

          &__code:focus {
            box-shadow: none !important;
          }

          &__showCode {
            background: $aux-yellow;
            font-size: $font-size-base;

            .code {
              letter-spacing: 0.15rem;
            }

            .link {
              color: $aux-black;
              font-weight: $font-weight-normal;
              font-size: .7rem;
            }

            .link:hover {
              color: $aux-black;
              text-decoration: none;
              opacity: .5
            }
          }
        }
      }

      &__thumbnail {
        z-index: 5;
        top: 0;
      }

      &__mask {
        background-color: rgba(0, 0, 0, 0);
        top: 0;
        z-index: 10;

        /* MBQ */
        button {
          display: none;
          font-size: 0.7rem;
          border-radius: 1rem;
          cursor: pointer;
        }
        /* MBQ */

        a {
          text-decoration: none;
          display: contents;

          button {
            display: none;
            font-size: 0.7rem;
            border-radius: 1rem;
            cursor: pointer;
            letter-spacing: 0.15rem;

          }
        }

      }

      &__mask:hover {
        background-color: rgba(0, 0, 0, 0.5);
        border: solid .3rem $dark-blue;

        button {
          display: inline-block;
        }
      }
    }
  }

  &__modal {
    max-width: 800px;

    &__instructions{
      font-size: 0.8rem !important;
    }

    &__token {
      min-width: 75%;
      max-width: 75%;
      border: solid .1rem $aux-grey400;

      &__info {
        font-size: .8rem;
        background: $aux-chinaIvory;

        span:first-of-type {
          color: $aux-grey700;
        }

        a {
          color: $aux-black;
        }
        p{
          margin-bottom: 0 !important;
        }
      }

      &__number {
        font-size: 2.5vw;
        letter-spacing: 0.65rem;
        background: $aux-yellow;

        &__secondaryInfo {
          font-size: .8rem;
          letter-spacing: normal;
          border-top: solid 1px $aux-greyBlue;
          color: $aux-greyBlue;
        }
      }
    }

    &__students {
      min-width: 80%;
      max-width: 80%;

      label {
        cursor: pointer !important;
      }

      &__question {
        color: $aux-grey300;
      }
    }

    &__sendInfo {
      width: 55%;
      border-radius: 1.5rem;
      font-weight: $font-weight-bold;
      background: #b50838;
      border: none;
    }

    &__sendInfo:hover {
      background: #b50838;
      opacity: .5;
    }

    &__sendInfo:focus {
      background: #b50838 !important;
      opacity: .5;
      border-color: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &__sendInfo:nth-child(1) {
      width: 40%;
      background: $aux-white;
      color: $aux-grey300;
      border: solid 1px $aux-grey300;
    }

    &__sendInfo:nth-child(1):hover {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border: solid 1px $aux-grey300 !important;
    }

    &__sendInfo:nth-child(1):focus {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border: solid 1px $aux-grey300 !important;
    }
  }
}

.blink {
  max-width: 50%; // isolated only for lg

  &__code span {
    background: $aux-yellow;
    letter-spacing: .8rem;

  }
}

.plus {
  &__Row {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
  }

  &__Col {
    flex: 0 0 auto;
    padding: 0 12px;
    flex-basis: 100%;

    /* MBQ */
    @include media-breakpoint-up(sm) { flex-basis: 25%; }
    /* MBQ */

    max-width: 100%;
  }
}

.plus__Col .products__card__inside__mask button.blink_code_students {
  background-color: rgba(133, 201, 57, 1) !important; 
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.plus__Col .products__card__inside__mask button.blink_code_students:hover {
  background-color: rgba(133, 201, 57, 0.75) !important;
}

.plus__Col .products__card__inside__mask button.blink_code_students:focus {
  background-color: rgba(133, 201, 57, 1) !important;
}

.plus__Col .products__card__inside__mask button.blink_code {
  background-color: rgba(253, 126, 20, 1) !important; 
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.plus__Col .products__card__inside__mask button.blink_code:hover {
  background-color: rgba(253, 126, 20, 0.75) !important;
}

.plus__Col .products__card__inside__mask button.blink_code:focus {
  background-color: rgba(253, 126, 20, 1) !important;
}

@media (min-width: 768px) {
  .plus__Col {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  } 
  .products__demo {
    top: 1.5rem;
    left: -0.5rem;
    width: 3.5rem;
  }    
}

@media (min-width: 992px) {
  .plus__Col {
    flex-basis: 20%;
    max-width: 20%;
  }
  .products__demo {
    top: 1rem;
    left: -0.25rem;
    width: 2.5rem;
  }    
}

@media (min-width: 1200px) {
  .plus__Col {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .products__demo {
    top: 1.5rem;
    left: -0.5rem;
    width: 3.5rem;
  }
    
}
