.SigninForm {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: $font-family-sans-serif;
  .img-container {
    border: none;
    cursor: pointer;
  }
  h1 {
    margin-top: 1rem;
    /*font-size: 2.7rem;*/
    font-size: 2rem;
    color: $aux-grey700;
    font-weight: $font-weight-bold;
  }
  button {
    font-family: $font-family-sans-serif;
    font-size: 16px;
  }
  .error {
    color: $red-error-form;
  }
  .link {    
    &__forgotpassword {
      color: $clear-blue;
      font-size: 0.875rem;
      text-decoration: none;
    }
    &__forgotpassword:hover {
      color: $cyan75;
    }
    &__help {
      color: $clear-blue;
      font-size: 0.875rem;
      text-decoration: none;
      & .glyphicon-question-sign {
        color: $aux-grey300;
        min-width: 0.625rem;
        padding: 0.188rem 0.438rem;
        font-size: 1.063rem;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        border-radius: 0.625rem;
        white-space: nowrap;
        vertical-align: baseline;
        display: inline-block;
        top: 0.188rem;
      }
    }
    &__help:hover {
      color: $cyan75;
    }
  }
  .h1 {
    &__oauth2 {
      font-size: 1.75rem;
      font-weight: 400;
      color: $aux-grey-title-oauth2;
    }
  }
  .p {
    &__oauth2 {
      display: block;
      margin-top: 0.625rem;
      font-size: 1rem;
      color: $aux-grey-subtitle-oauth2;
    }
  }                  
}