.banners {
  text-align: center;
  & > .row {
    margin: $spacer/2;
    & > .banner {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    & > .row {
      margin: auto;
      & > .banner {
        margin: $spacer/2;
      }
      & > .banner:first-child {
          margin-left: 0px;
      }
      & > .banner:last-child {
        margin-right: 0px;
      }
    }
  }

  .banner {
    border-radius: 50px;
    background-color: #F7F7F7;
    font-size: 14px;
    height: inherit;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & > div {
      cursor: pointer;
    }

    p {
      margin: 0;
    }
  }
}
