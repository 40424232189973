.container-gpa {
  & .adopcion-recursos .well.well-cards-material {
    & h4 {
      @include media-breakpoint-up(md) { min-height: 60px; }
      font-size: 17px;
      line-height: 20px;
      color: $aux-grey900;
    }

    & h6 {
      margin-bottom: 0;
      font-weight: 400;
      color: $aux-grey900;
    }

    & p.course {
      display: block;
      display: -webkit-box;
      @include media-breakpoint-up(md) { height: 40px; }      
      overflow: hidden;
      line-height: 20px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & p.state {
      @include media-breakpoint-up(md) { min-height: 40px; }
      font-family: $font-family-sans-serif;
      font-weight: 700;
      color: $aux-grey900;

      & .glyphicon-send {
        color: $magenta47;
      }
      
      & .glyphicon-ok {
        color: $green7ED321;
      }

      & .glyphicon-remove {
        color: $redF45531;        
      }

      & .glyphicon-time {
        color: $orangeF5A623;
      }

      & span.help-state {
        cursor: pointer;
      }
    }
  }

  & .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: $font-family-glyphicon;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  & .invisible {
    visibility: hidden;
  }  
}

.gpa-tooltip {
  font-family: $font-family-button;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
}