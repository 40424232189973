.professional-development {
  // max-width: 100%;

  .pdcarousel {
    color: $aux-grey700;

    &-starred {
      background-color: $aux-grey200;
      box-shadow: 0 5px 9px 0 $aux-grey300;

      .starred-prev {
        font-size: 30px;
        position: absolute;
        top: 50%;
        cursor: pointer;
        color: $aux-grey300;
        width: 40px;
        height: 40px;
        transform: translate(0, -50%);
        display: block;
        left: -40px;
      }
      .starred-next {
        font-size: 30px;
        position: absolute;
        top: 50%;
        cursor: pointer;
        color: $aux-grey300;
        width: 40px;
        height: 40px;
        transform: translate(0, -50%);
        display: block;
        right: -40px;
      }

      .asset-starred {
        color: $aux-grey700;

        &:hover {
          cursor: pointer;
        }

        &-category {
          font-size: 1.2em;
        }

        &-title {
          font-size: 2em;
          font-weight: bold;
        }
      }
    }

    &-title {
      font-size: 1.2em;
      color: $aux-grey700;
      font-weight: 700;
    }
    &-nav {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
      text-align: right;
      font-size: 0.8em;
    }
    .slick-slider {
      margin: 0 -5px;

      .slick-track {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .asset-card {
      margin: 5px;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0;
      cursor: pointer;

      &-image {

        & img {
          width: 100%;
          height: 100%;
        }

        &-viewed {
          display: inline-block;
          background-color: $dark-blue;
          color: $aux-white;
          border-radius: 15%;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
          font-size: 0.8rem;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
      .card-body {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        height: 150px;
        max-height: 150px;
        padding: 0;

        .asset-card-category {
          font-size: 0.8em;
          height: 20px;
        }
        .asset-card-title {
          height: 50px;
          min-height: 50px;
          font-size: 1.1em;
          overflow: hidden;
          font-weight: 600;
        }
        .asset-card-author {
          font-size: 0.8em;
          height: 20px;
        }
        .asset-card-views {
          border-left: 1px solid $aux-grey700;
        }
      }
    }
  }
}

.asset {
    color: $aux-grey700;

    &-back {
      cursor: pointer;
    }

    &-preview {
      text-align: center;
      &-container {
        background-color: $dark-blue;
      }
    }

    &-category {
      color: $aux-grey700;
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 500;
    }

    &-title {
      color: $aux-grey700;
      font-size: 1.8em;
      font-weight: bold;
    }

    &-date {
      font-size: 0.9rem;
      font-weight: 500;
      color: $aux-grey700;
      border-bottom: solid 1px $aux-grey700;
      padding-bottom: 0.8rem;
    }

    &-views {
      color: #bfbfbf;
      font-size: 0.8rem;
      font-weight: 500;
    }

    &-social-separator {
      color: #bfbfbf;
      line-height: 1rem;
      font-size: 2rem;
      font-weight: 500;
    }

    &-description {
      color: $aux-grey700;
    }
}
