.button {
    &-close {
        cursor: pointer;

        & img {
            width: 20px;
            height: 20px;
        }
    }

    &-rounded {
        padding: 0;
        text-align: center;
    }
}

.btn-LocalPublishingManagedSurvey {
    background-color: #c8dc00;
    border: 2px solid #002147;
    color: #002147;
    font-size: 16px;
    font-weight: 500;
    border-radius: 15px;
    padding: 6px 8px;
}
