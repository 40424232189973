.PasswordForgot {
  margin-bottom: 2rem;
  h1 {
    margin-top: 1rem;
    font-size: 2.7rem;
    color: $aux-grey700;
    font-weight: $font-weight-bold;
  }
  p {
    color: $aux-grey600;
  }
  .error {
    color: #dc3545;
  }
}
