.invoice-panel {
    min-height: 28rem;
    background-color: $aux-grey300;

    &-button-container {
        background-color: $aux-grey200;
    }

    &-button {
        height: 150px;
        width: 200px;
    }
}
// .customer-user-screen-container{
  // background: #f0f8ff;

  // & .pagination-container{
      // @media only screen and (max-width: 768px) {
              // padding-top: 10px;      
      // }
  // }

  // & .pagination{
      // margin-bottom: 0;
      // & .page-link{
          // border: none;
      // }
  // }

  // & form{
      // & .search-button{
          // padding-top: 30px;
          // @media only screen and (max-width: 991px) {
              // padding-top: 0;      
          // }
      // }
  // }

  // & table{
      // & thead{
          // color:white;
          // & .text-underline{
              // text-decoration: underline;
              // cursor: pointer;
          // }
      // }
      // & tbody{
          // border-bottom: 1px solid #dee2e6;
      // }
  // }

  // & .disable{
      // opacity: 0.5;
      // cursor: not-allowed;
  // }
// }
