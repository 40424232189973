.activationCodeForm {
    p {
      color: #979797;
      font-size: 12px;
      text-align: center;
      font-family: 'Montserrat',"Helvetica Neue",Helvetica,Arial,sans-serif;
      margin-bottom: 0%;
    }
    p.searchOk {
      color: #979797;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      font-family: 'Montserrat',"Helvetica Neue",Helvetica,Arial,sans-serif;
      margin-bottom: 0%;
    }
    label {
      font-size: .8em;
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      margin-top: 0%;
      margin-bottom: 0%;
    }
    input {
      font-size: .7em;
      margin-right: auto;
    }
    &__error {
      font-family: 'Montserrat',"Helvetica Neue",Helvetica,Arial,sans-serif; 
      font-size: .8em;
    }
    &__navLink {
      font-family: 'Montserrat',"Helvetica Neue",Helvetica,Arial,sans-serif; 
      font-size: .8em;
    }
}    