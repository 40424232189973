// Breadcrumb styles
.breadcrumbOUP {

  font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;

  ol {
    padding: 0.7rem 1rem;
    background: $aux-grey400;
    border-radius: 0 !important;   
    margin-bottom: 0; 
  }

  button {
    top: 0.4rem;
    right: 1.4rem;
    font-size: 0.8rem;
    border-radius: 1.5rem;
    border-color: $aux-grey600;
    color: $aux-grey600;
    &:hover{
      background: $aux-grey600;
      color: $aux-grey300;
    }
  }

  &__item {
    color: $aux-grey600 !important;
    transition: 0.4s;
  }
}

a.breadcrumbOUP__item:hover{
  color: $aux-grey300 !important;
  text-decoration: none;
}
