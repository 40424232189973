.PromotionHome {

	.modal-header {
		padding: 2rem 1rem;
		border-bottom: none;		
	}

	.modal-title {
		text-align: center;
		width: 100%;
	}

	.modal-footer {
		border-top: none;
	}

	img {
		width: inherit;
	}
}