.notification {
  &__gpa {
    & .modal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      display: none;
      overflow: hidden;

      & .modal-content {
        /*width: 480px;*/
        float: left;
        padding: 10px 30px 30px 30px;
        font-family: $font-family-sans-serif;
        font-size: 13px;
        border-radius: 6px;
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
      }

      &__header {
        color: $aux-white;
        background-color: rgb(90, 183, 191);
        padding-bottom: 50px;

        button.close {
          color: $aux-grey900;
          filter: alpha(opacity=80);
          border: 1px solid $aux-grey600;
          border-radius: 35px;
          opacity: .8;
          cursor: pointer;
          background: transparent;
          border: 0;
          border: 1px solid $aux-grey200;
          background-color: $aux-white;
          padding: 6px 9px;
          margin-top: -5px;
          margin-right: -5px;
          font-size: 13px;
        }
      }

      button.close:hover, button.close:focus {
        filter: alpha(opacity=50);
        opacity: .5;
      }

      &-body {
        float: left;
        margin-top: 0;
        text-align: left;
        /*width: 480px;*/
        padding-top: 0;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: $font-family-sans-serif;
        font-size: 13px;
        color: $aux-grey600;
      }
    }

    & .modal__header small, & .modal__header .small {
      font-size: 15px;
      font-weight: 400;
      font-family: $font-family-button;
    }

    & .modal__header button.close:hover, & .modal__header button.close:focus {
      filter: alpha(opacity=50);
      opacity: .5;
    }
  }
}