.ct-product-error {
  font-family: "Fira Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $product-viewer-font;
  text-align: left;
  background-color: $product-viewer-background !important;

  /*min-height: 28rem;*/
  min-height: inherit;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: transparent solid 0.1px;

  /*float: inline-start;*/
  position: absolute;
  top: 0;
  padding-top: 5rem;

  & h1.title {
    font-size: 2.5rem;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: $product-viewer-title;
  }

  & .title-underlined {
    word-break: break-word;

    & strong {
      box-shadow: inset -1px -35px 0px -22px $product-viewer-box-shadow;
    }
  }

  & a {
    color: $product-viewer-link;
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
  }
}