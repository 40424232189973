.BlinkCptLicenceContent {
  .col-licence {
    height: 26.25rem;
    background-color: $aux-grey-background-oauth2;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 1.25rem 0rem 1.25rem 0rem;
    border: 0;

    .row-licence {
      background-color: $aux-grey-background-oauth2;
      text-align: justify;
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 0.813rem;
      color: $aux-grey-color-oauth2;

      .h3 {
        &__licence {
          font-size: 1.25rem;
        }
      }

      .h4 {
        &__licence {
          font-size: 1rem;
        }
      }

      .h5 {
        &__licence {
          font-size: 0.875rem;
        }
      }
    }
  }
}