.main-content {
  min-height: 28rem;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: transparent solid 0.1px;
}
/* chat */
#comm100-button-1952 {
  height: 0;
}

/* recaptcha */
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha_terms{
  color: $aux-grey600;
}
