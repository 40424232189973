.activationCode {
    p {
      color: #979797;
      font-size: 12px;
      text-align: center;
      font-family: 'Montserrat',"Helvetica Neue",Helvetica,Arial,sans-serif;
      margin-bottom: 0%;
    }
    &__modal-open {
      overflow:auto;
      padding-right:0 !important;
    }
}    