.promotion-title {
	.promotion-ad, .promotion-dm {
		background: $blue-promotion-title-background;
		color: $aux-white;

		a, a:hover, a:visited, a:focus, a:active {
			color: $aux-white;
			text-decoration: underline;
		}

		.button-premium-primary {
			/*background: $blue-promotion-title-background;*/
			border: 0.15rem solid $aux-white;
		}

		.div {
			&__close {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	.promotion-dm {
		.div {
			&__content {
				p {
					margin-top: 0.8rem;
				}
			}
		}
	}

	.promotion-ad {
		.container {
			margin-top: 0.4rem;
		}

		.row  {
			align-items: center !important;
		}
	}
}