.hp__modal {
  max-width: 90vw;
  font-size: 0.95rem;

  &__pointer {
    cursor: pointer;
  }

  p {
    font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
  }

  .modal-content {
    background: $aux-grey200;
  }

  background: $aux-grey200;

  &__content {
    color: $aux-grey700;

    h1 {
      font-size: 2.8rem;
      font-weight: $font-weight-bold;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: $font-weight-bold;
    }
  }

  &__footer {
    margin-top: 2rem;

    button {
      padding: 0.8rem 3rem;
      margin: 1rem;
      border-radius: 1.5rem;
      border-width: 0.1rem;
    }

    &__accept {
      background: $primary;
      font-weight: $font-weight-bold;
      border: none;
    }

    &__accept:hover {
      opacity: 0.75;
      background: $primary;
    }

    &__accept:focus {
      opacity: 1;
      background: $primary !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &__decline {
      background: $aux-white;
      border-color: $aux-grey600;
      color: $aux-grey600;
    }

    &__decline:hover {
      background: $aux-grey600;
      border-color: $aux-grey600;
      color: $aux-white;
    }

    &__decline:focus {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border-color: $aux-grey600 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.hp__form {
  color: $aux-grey600;

  &__alert {
    color: #f66;
    background-color: #faebeb;
    padding: 0.375rem 0.75rem;
    font-size: .95rem;
  }

  .is-invalid:focus {
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  .is-invalid {
    background-image: none !important;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    color: $aux-grey700;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    color: $aux-grey700;
  }

  p {
    font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
  }

  strong.dark {
    color: $aux-black;
  }

  .delete_icon {
    color: #dc3545;
    font-size: .7rem;
    font-weight: 700;
  }

  /*div.birthdate-disabled {
    padding-right: 0;
  }*/

  label {
    font-family: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: .75rem;
    color: $aux-grey900;
    letter-spacing: .4px;
  }

  input,
  select {
    font-family: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: .9rem;
    background-color: $aux-grey200;
  }

  input:focus,
  select:focus {
    outline: none;
    box-shadow: none;
    background-color: $aux-grey200;
  }

  input:disabled {
    background-color: $aux-grey300;
    cursor: not-allowed;
  }

  &__spinner {
    margin-left: -2rem;
    margin-top: -1rem;
  }
  &__spinner-select {
    margin-left: -3rem;
  }

  button {
    padding: 0.8rem 3rem;
    border-radius: 1.5rem;
    border-width: 0.1rem;
  }

  &__accept {
    background: $primary;
    font-weight: $font-weight-bold;
    border: none;
  }

  &__accept:hover {
    opacity: 0.75;
    background: $primary;
  }

  &__accept:focus {
    opacity: 1;
    background: $primary !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.summary__Modal {
  max-width: 60vw;
  font-size: 0.95rem;

  h6 {
    font-weight: $font-weight-bold;
    color: $aux-grey700;
  }

  p {
    font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
    font-size: .85rem;
    color: $aux-grey600;
  }

  li {
    color: $aux-grey700;
  }

  &__noBorder {
    .list-group-item {
      border: none;
      color: $aux-grey600;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
      font-size: .85rem;

      strong.dark {
        color: $aux-black;
      }
    }
  }

  &__total {
    font-weight: $font-weight-bold;
  }

  button {
    padding: 0.8rem 3rem;
    border-radius: 1.5rem;
    border-width: 0.1rem;
  }

  &__accept {
    background: $primary;
    font-weight: $font-weight-bold;
    border: none;
  }

  &__accept:hover {
    opacity: 0.75;
    background: $primary;
  }

  &__accept:focus {
    opacity: 1;
    background: $primary !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &__decline {
    background: $aux-white;
    border-color: $aux-grey600;
    color: $aux-grey600;
  }

  &__decline:hover {
    background: $aux-grey600;
    border-color: $aux-grey600;
    color: $aux-white;
  }

  &__decline:focus {
    background: $aux-grey600 !important;
    color: $aux-white !important;
    border-color: $aux-grey600 !important;
    outline: none !important;
    box-shadow: none !important;
  }

}

.summary__pay {
  max-width: 45vw;

  h5 {
    text-align: center;
    font-weight: $font-weight-bold;
  }
}

@media (min-width: 768px) {
  .hp__modal {
    max-width: 80vw;
  }
}

@media (min-width: 992px) {
  .hp__modal {
    max-width: 75vw;
  }
}