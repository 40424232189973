.products {
  & h1 {
    color: $aux-grey700;
    font-size: 18px;
  }

  & div.products-subtitle {
    color: $aux-grey700;
  }

  & div.installers {
    background-color: $white;
    border-collapse: collapse;
    border-spacing: 2px;
  }

  & table.installers {
    border-collapse: collapse;
    border-spacing: 2px;
  }

  &-menu-item {
    border: 1px solid $orange;
    border-radius: 5px;
    color: $orange;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &-menu-item:hover, &-menu-item.active {
    background-color: $orange;
    color: white;
    text-align: center;
  }

  &__demo {
    z-index: 15;
    top: 1.5rem;
    left: -0.5rem;
    width: 4.5rem;
  }

  &__card {
    height: 0;
    overflow: hidden;
    /*padding-top: 140%;*/
    background: $aux-white;

    &__inside {
      top: 0;
      left: 0;

      &__legend {
        font-weight: $font-weight-bold;
        font-size: .9rem;

        /* PREM-4730 */
        &.student-link-legend {
          background-color: $white;
          position: absolute;
          height: auto;
          padding: 0.5rem;
          text-align: left;
        }
        /* PREM-4730 */

        &__absolute {
          position: absolute;
          bottom: 0;
          z-index: 20;
          width: 100%;
        }

        &__expiry {
          font-weight: $font-weight-normal;
          font-size: .8rem;

          &__student {
            background: $aux-chinaIvory !important;

            button {
              border-radius: 1rem;
              font-size: 0.8rem;
              background: $aux-grey700;
              border: none;
              width: 100%;
              color: $aux-black;
            }

            button:hover {
              background: $aux-grey700;
              color: $aux-black;

              span {
                opacity: .5;
              }
            }

            button:focus {
              box-shadow: none !important;
              background: $aux-grey700 !important;

              span {
                opacity: .5;
              }
            }
          }

          &__code {
            background: $aux-chinaIvory !important;
            border: none;
            color: $aux-black;
            font-size: .7rem;
            font-weight: $font-weight-bold;
            letter-spacing: 0.15em;
          }

          &__code:hover {
            color: $aux-black !important;
          }

          &__code:focus {
            box-shadow: none !important;
          }

          &__showCode {
            background: $aux-yellow;
            font-size: $font-size-base;

            .code {
              letter-spacing: 0.15rem;
            }

            .link {
              color: $aux-black;
              font-weight: $font-weight-normal;
              font-size: .7rem;
            }

            .link:hover {
              color: $aux-black;
              text-decoration: none;
              opacity: .5
            }
          }
        }
      }

      &__thumbnail {
        z-index: 5;
        top: 0;
      }

      &__mask {
        background-color: rgba(0, 0, 0, 0);
        top: 0;
        z-index: 10;

        /* MBQ */
        button {
          display: none;
          font-size: 0.7rem;
          border-radius: 1rem;
          cursor: pointer;
        }
        /* MBQ */

        a {
          text-decoration: none;
          display: contents;

          button {
            display: none;
            font-size: 0.7rem;
            border-radius: 1rem;
            cursor: pointer;
            letter-spacing: 0.15rem;

          }
        }

      }

      &__mask:hover {
        background-color: rgba(0, 0, 0, 0.5);
        border: solid .3rem $dark-blue;

        button {
          display: inline-block;
        }
      }
    }
  }

  &__modal {
    max-width: 800px;

    &__instructions{
      font-size: 0.8rem !important;
    }

    &__token {
      min-width: 75%;
      max-width: 75%;
      border: solid .1rem $aux-grey400;

      &__info {
        font-size: .8rem;
        background: $aux-chinaIvory;

        span:first-of-type {
          color: $aux-grey700;
        }

        a {
          color: $aux-black;
        }
        p{
          margin-bottom: 0 !important;
        }
      }

      &__number {
        font-size: 2.5vw;
        letter-spacing: 0.65rem;
        background: $aux-yellow;

        &__secondaryInfo {
          font-size: .8rem;
          letter-spacing: normal;
          border-top: solid 1px $aux-greyBlue;
          color: $aux-greyBlue;
        }
      }
    }

    &__students {
      min-width: 80%;
      max-width: 80%;

      label {
        cursor: pointer !important;
      }

      &__question {
        color: $aux-grey300;
      }
    }

    &__sendInfo {
      width: 55%;
      border-radius: 1.5rem;
      font-weight: $font-weight-bold;
      background: #b50838;
      border: none;
    }

    &__sendInfo:hover {
      background: #b50838;
      opacity: .5;
    }

    &__sendInfo:focus {
      background: #b50838 !important;
      opacity: .5;
      border-color: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &__sendInfo:nth-child(1) {
      width: 40%;
      background: $aux-white;
      color: $aux-grey300;
      border: solid 1px $aux-grey300;
    }

    &__sendInfo:nth-child(1):hover {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border: solid 1px $aux-grey300 !important;
    }

    &__sendInfo:nth-child(1):focus {
      background: $aux-grey600 !important;
      color: $aux-white !important;
      border: solid 1px $aux-grey300 !important;
    }
  }
}

.blink {
  max-width: 50%; // isolated only for lg

  &__code span {
    background: $aux-yellow;
    letter-spacing: .8rem;

  }
}

.plus {
  &__Row {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
  }

  &__Col {
    flex: 0 0 auto;
    padding: 0 12px;
    flex-basis: 100%;

    /* MBQ */
    @include media-breakpoint-up(sm) { flex-basis: 25%; }
    /* MBQ */

    max-width: 100%;
  }
}

.plus__Col .products__card__inside__mask button.blink_code_students {
  background-color: rgba(133, 201, 57, 1) !important; 
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.plus__Col .products__card__inside__mask button.blink_code_students:hover {
  background-color: rgba(133, 201, 57, 0.75) !important;
}

.plus__Col .products__card__inside__mask button.blink_code_students:focus {
  background-color: rgba(133, 201, 57, 1) !important;
}

.plus__Col .products__card__inside__mask button.blink_code {
  background-color: rgba(253, 126, 20, 1) !important; 
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.plus__Col .products__card__inside__mask button.blink_code:hover {
  background-color: rgba(253, 126, 20, 0.75) !important;
}

.plus__Col .products__card__inside__mask button.blink_code:focus {
  background-color: rgba(253, 126, 20, 1) !important;
}

.plus__Col .products__card__inside__mask button.pangea_code {
  background-color: rgba(2, 148, 211, 1) !important;
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.plus__Col .products__card__inside__mask button.pangea_code:hover {
  background-color: rgba(2, 148, 211, 0.75) !important;
}

.plus__Col .products__card__inside__mask button.pangea_code:focus {
  background-color: rgba(2, 148, 211, 1) !important;
}

@media (min-width: 768px) {
  .plus__Col {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  } 
  .products__demo {
    top: 1.5rem;
    left: -0.5rem;
    width: 3.5rem;
  }    
}

@media (min-width: 992px) {
  .plus__Col {
    flex-basis: 20%;
    max-width: 20%;
  }
  .products__demo {
    top: 1rem;
    left: -0.25rem;
    width: 2.5rem;
  }    
}

@media (min-width: 1200px) {
  .plus__Col {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .products__demo {
    top: 1.5rem;
    left: -0.5rem;
    width: 3.5rem;
  }
}

.student {
    &-link {
        &-cover {
            width: 100%;
        }

        &-front {
            height: 100%;
            width: 100%;

            /* PREM-4730 */
            /*background-color: #e7b513;*/
            background-color: $student-link-background;
            border: 0.5rem solid $student-link-border;
            /* PREM-4730 */

            color: $white;
            font-size: 12vw;
            line-height: 1.2em;
            @include media-breakpoint-up(sm) { font-size: 3vw }
            @include media-breakpoint-up(md) { font-size: 4.28vw }
            @include media-breakpoint-up(lg) { font-size: 2.14vw }
            @include media-breakpoint-up(xl) { font-size: 1.28vw }
            position: absolute;
            top: 0;
        }

        &-title {
            height: 60%;
        }

        &-share {
            height: 40%;

            /* PREM-4730 */
            /*bottom: 0;*/
            bottom: 0.5rem;
            /* PREM-4730 */

            position: absolute;

            &-content {
                height: 100%;
                color: $white;
                background-color: #002147;
                font-size: 10vw;
                line-height: 1.2em;
                @include media-breakpoint-up(sm) { font-size: 1.8vw }
                @include media-breakpoint-up(md) { font-size: 3vw }
                @include media-breakpoint-up(lg) { font-size: 1.2vw }
                @include media-breakpoint-up(xl) { font-size: 0.7vw }

                /* PREM-4730 */
                /*
                border-right: 1.2px solid $white;
                border-left: 1.2px solid $white;
                border-top: 1.2px solid $white;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                */
                border-top: 0.05rem solid $white;
                /* PREM-4730 */
            }
        }

        &-linkmodal {
            &-container {
                border: 1px solid $aux-grey300;
            }

            &-title {
                background-color: #FFF6D5;
                color: $aux-grey700;
            }

            &-link {
                background-color: #FED442;
                font-size: 0.9em;
                font-weight: bold;
                color: $black;
            }
        }
    }
}

/* >>MBQ TODO AI POC */
#bt-ai {
  /*margin: 0 25px;*/
  cursor: pointer;
  border-radius: 1.5rem;
  box-shadow: none !important;

  & .card:hover,
  & .card:focus {
    border: solid 0.3rem #002147;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5) !important;
  }

  & .products__card {
    border: solid 0.3rem rgba(0, 0, 0, 0.5);
  }

  /*
  & .products__card:hover,
  & .products__card:focus {
    border: solid 0.3rem #002147;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
  }
  */
}

#modal-ai {
  & label {
    font-weight: 600;
  }

  & span.glyphicon-download {
    color: #002147;
    font-size: 40px; 
  }

  & span.glyphicon-download.inactive {
    opacity: .5;
    cursor: default;
  }

  & span.glyphicon-download.active {
    opacity: 1;
    cursor: pointer;
  }
  & span.glyphicon-download.active:hover,
  & span.glyphicon-download.active:focus {
    color: #0294d3;
  }
}
/* MBQ TODO AI POC<< */