.jumbotron {
  &__banner {
    margin-bottom: 0;
    margin-top: 0;
    padding: 1rem;

    .container {
      padding: 0;
      width: 100%;
      max-width: 100%;
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    /*.col {
      padding-right: 0;
      padding-left: 0;
    }*/
  }
}