.container-gpa {
  & ul, & .gpanavbar ol {
    margin-top: 0;
  }

  & .breadcrumb {
    @include media-breakpoint-up(md) { padding: 15px 25px; }
    @include media-breakpoint-down(sm) { font-size: 14px; }
    margin-bottom: 0;
    background-color: $aux-grey400;
    border-radius: 0;
    list-style: none;

    & > li {
      display: inline-block;
    }

    & > li + li::before {
      padding: 0 5px;
      color: $aux-grey600;
      content: ">\A0";
    }

    & a {
      color: $aux-grey600;
    }

    & a:hover {
      color: $aux-grey300;
    }
  }

  & a {
    text-decoration: none;
    background-color: transparent;
    color: $clear-blue;
  }

  & a:hover {
    color: $cyan75;
  }

  & btn:hover, & a:hover, & a:active {
    text-decoration: none;
    -webkit-transition: color .3s linear;
    -o-transition: color .3s linear;
    transition: color .3s linear;
  }

  & a:hover, & a:active {
    text-decoration: none;
    outline: 0;
  }

  & a:hover, & a:focus {
    color: $blue014D6E;
    text-decoration: none;
  }

  & a:focus, & a:hover {
    color: $blue23527C;
    text-decoration: underline;
  }
}