.container-gpa {
  & .adopcion-recursos {
    & .badge.number-adopcion {
      /*padding: 3px 11px;*/
      margin-left: 4px;
      font-weight: 400;
      vertical-align: top;
      background-color: $redF45531;
    }

    & .well.well-cards-adopcion {
      background-color: $aux-white;
      border-color: transparent;
      border-radius: 5px;
      -webkit-box-shadow: 0 2px 2px 0 $black17;
      box-shadow: 0 2px 2px 0 $black17;
      @include media-breakpoint-up(md) { height: 268px; }
      
      & .badge.number-adopcion {
        padding: 3px 8px;
        margin-top: -28px;
        margin-right: -28px;
      }

      & .row-sm-center {
        @include media-breakpoint-down(sm) { text-align: center; }
      }

      & h4 {
        display: block;
        @include media-breakpoint-up(md) { height: 60px; }        
        overflow: hidden;
        font-size: 17px;
        line-height: 20px;
        color: $aux-grey900;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      & p {
        font-size: 14px;

        &.course {
          display: block;
          @include media-breakpoint-up(md) { height: 40px; }
          overflow: hidden;
          line-height: 20px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &.state {
          @include media-breakpoint-up(md) { height: 20px; }
          margin-bottom: 20px;
          font-family: $font-family-sans-serif;
          font-weight: 700;
          color: $aux-grey900;
          font-size: 13px;

          & .glyphicon-ok {
            color: $green7ED321;
          }
          
          & .glyphicon-send {
            color: $magenta47;
          }
          
          & .glyphicon-remove {
            color: $redF45531;
          }
          
          & .glyphicon-time {
            color: $orangeF5A623;
          }
          
          & p.state span.help-state {
            cursor: pointer;
          }
        }
      }
    }
  }

  & .pull-right {
    float: right !important;
  }
  
  & .badge {
    display: inline-block;
    min-width: 10px;
    font-size: 14px;
    line-height: 1;
    color: $aux-white;
    text-align: center;
    white-space: nowrap;
    border-radius: 10px;
  }

  & .invisible {
    visibility: hidden;
  }
  
  & .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: $font-family-glyphicon;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }  
}

.gpa-tooltip {
  font-family: $font-family-button;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
}