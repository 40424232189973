@media (min-width: 768px) {
  .new_coupon {
    width: 80%;
  }
}

.new_coupon {
  max-width: 100%;
  max-height: 100%;
}

.w-15px {
  width:15px;
}

.w-25px {
  width:25px;
}

.bt-new-coupon .top-label {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #002147;
  font-size: 21px;
  font-weight: 500;
  align-items: center;
}
.bt-new-coupon .new_coupon {
  width: auto;
}
