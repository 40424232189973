.container-notification-changed-adoptions {
  & h1 {
      color: $aux-grey700;
      font-weight: $font-weight-bold;
      font-size: 2rem;
      text-align: center;
  }
  & h2 {
      color: $aux-grey700;
      font-size: 1.3rem;
  }
}
