.coupons-title-new {
    border: 3px solid #ffe12d;
    position: relative;
}

.coupons-title-new-label {
    position: absolute;
    height: 30px;
    width: 70px;
    border: 1px solid black;
    color: black;
    text-align: center;
    background-color: #ffe12d;
    border-radius: 22px;
    display:flex;
    z-index: auto;
    font-size: 14px;
    top: -16px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
