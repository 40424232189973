$category_height: 25px;
.news-container {
  background-color: $aux-grey-background;
}
.newsFeed {

  h1 {
      font-size: 2rem;
      color: $aux-grey700;
      font-weight: $font-weight-bold;

      .totalNumber {
          font-size: 0.85rem;
          padding: 0.1rem .8rem;
          vertical-align: text-top;
          background: $clear-blue;
          color: $white;
          border-radius: 0.8rem;
          display: inline-block;
      }
  }

  &__navTab {
      justify-content: end;
      border: none;

      &__link {
          color: $aux-grey600 !important;
          cursor: pointer;
          border: none !important;
          font-size: ($font-size-base * .875);
          font-weight: $font-weight-bold;
      }

      &__link:hover {
          border-bottom: solid 4px $black !important;
      }

      &__link.active {
          color: $black !important;
          background-color: transparent !important;
          border-bottom: solid 4px $black !important;
      }
  }

}

.newsItem {
  cursor: pointer;
  color: #6b7074;
  margin-bottom: 15px;
  border-radius: 0;

  &__0 { background: #b50837; color: white; }
  &__1 { background: #b7557f; color: white; }
  &__2 { background: #d5a722; color: white; }
  &__3 { background: #02a2b1; color: white; }
  &__4 { background: #0294b3; color: white; }
  &__5 { background: #8b8e07; color: white; }

  .card-subtitle {
    padding-bottom: 1rem;
    font-size: 80%;
    font-weight: 400;
  }

  .news-card-img-container {
    width: 100%;
  }

  .news-card-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-body {
    padding: 0 10px;
    font-family: "PT Serif","Georgia","Times New Roman","Times","serif";
    font-size: 16px;
  }

  .card-text {
    padding-bottom: 1rem;
    margin-bottom: 0;
    font-size: 80%;
    font-weight: 400;
  }

  ul.categories {
    position: absolute;
  }

  .category_full {
    background-color: transparent;
    border: 0px;
    text-transform: uppercase;
    color: $white;
    top: 20px;
    left: 20px;
    z-index: 100;
    list-style: none;
    padding: 0;
    margin-bottom: 7px;

    p {
        display: inline;
        padding: 1px 6px;
        clear: left;
        float: left;
        margin-bottom: 0px;
        margin: 0px;
        letter-spacing: .6px;
        font-size: 11px;
        font-weight: 700;
        padding-bottom: 0px !important;
    }
  }

  .category {
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    color: $white;
    list-style: none;
    padding: 0;

    p {
        display: inline;
        padding: 1px 6px;
        clear: left;
        float: left;
        margin-bottom: 0px;
        margin: 0px;
        letter-spacing: .6px;
        font-size: 11px;
        font-weight: 700;
        padding-bottom: 0px !important;
    }

    &__0 { background-color: #b50837; }
    &__1 { background-color: #b7557f; }
    &__2 { background-color: #d5a722; }
    &__3 { background-color: #02a2b1; }
    &__4 { background-color: #0294b3; }
    &__5 { background-color: #8b8e07; }
  }

  @for $i from 1 through 10 {
    div.noimage_#{$i} {
      height: $i * $category_height;
    }
  }

  .modal-header {
    margin: 0;
    padding: 0;
    border: 0;

    & img {
      max-width: 100%;
      height: auto;
    }
  }

  .news-title {
    line-height: 1.45em;
    height: 6em;
    overflow: hidden;
  }

  .modal-title {
    width: 100%;
  }

  .modal-body {
    font-family: "PT Serif","Georgia","Times New Roman","Times","serif";
  }

  .card-title {
    font-family: 'Montserrat','Helvetica Neue',"Helvetica","Arial","sans-serif";
    font-weight: 700;
  }

  &.modal-dialog {
    cursor: default;
    max-width: 600px;
  }


  .modal-content {
    width: 600px;
    max-width: 100%;
    overflow: hidden;
    font-size: 17px;
  }

  .modal-content button.close {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 6px 9px;
    font-size: 13px;
    color: #3a3d3f;
    filter: alpha(opacity=80);
    border: 1px solid #979797;
    border-radius: 35px;
    opacity: .8;
    cursor: pointer;
    background: transparent;
    font-weight: bold;
    width: 25px;
  }

  .modal-footer {
    display: block;
    text-align: center;
    border: 0px;

    & button {
      min-width: 150px;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #fff;
      background-color: #01255d;
      filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
      background-repeat: repeat-x;
      border-color: transparent;
      border-radius: 30px;
    }
  }
}

