.TeacherRegister {
    color: $aux-grey600;

    h1, h5 {
        color: $aux-grey700;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .error {
        color: #dc3545;
    }
    .formtext {
        color: $aux-grey600;
    }
}
