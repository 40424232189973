.HPSuccess {
  h6 {
    font-size: 0.95rem;
    color: #979797;
    font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
  }

  p {
    font-size: 0.95rem;
    color: #979797;
    font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
  }

  h1{
    font-size: 2.8rem;
    font-weight: $font-weight-bold;
    color: $aux-grey700;
  }

  button {
    padding: 0.8rem 3rem;
    border-radius: 1.5rem;
    border-width: 0.1rem;
  }

  &__accept {
    background: $primary;
    font-weight: $font-weight-bold;
    border: none;
  }

  &__accept:hover {
    opacity: 0.75;
    background: $primary;
  }

  &__accept:focus {
    opacity: 1;
    background: $primary !important;
    outline: none !important;
    box-shadow: none !important;
  }

}