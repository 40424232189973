// Resources styles
.resources {

  &__tab__border {
    border-color: $aux-grey600 !important;
  }

  &__tab {
    width: 100%;
    padding-left: 1.3rem;
    padding-right: 1.3rem;

    &__item {
      width: 50%;
      cursor: pointer;
      font-size: 1.2rem;
      text-align: center;
      font-weight: $font-weight-bold;

      a {
        border: none !important;
        color: $aux-grey600;
        margin: 0 .5rem;
      }

      a:hover {
        color: $aux-black;
        border-bottom: solid .4rem $orange !important;
      }

      a.active {
        background: transparent !important;
        border-bottom: solid .4rem $orange !important;
      }
    }
  }
}
