.ThirdPartiesError {
    h1, h5 {
        text-align: center;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    p {
        text-align: center;
    }
    .error {
        color: #dc3545;
    }

    .link {    
        &__forgotpassword {
          color: $clear-blue;
          font-size: 14px;
          text-decoration: none;
        }
        &__forgotpassword:hover {
          color: $cyan75;
        }
        &__help {
          color: $clear-blue;
          font-size: 14px;
          text-decoration: none;
          & .glyphicon-question-sign {
            color: $aux-grey300;
            min-width: 10px;
            padding: 3px 7px;
            font-size: 17px;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            border-radius: 10px;
            white-space: nowrap;
            vertical-align: baseline;
            display: inline-block;
            top: 3px;
          }
        }
        &__help:hover {
          color: $cyan75;
        }
      }
}
