.create-fictitious-user-container{
    background: #f0f8ff;

    & form{
        & .search-button{
            padding-top: 30px;
            @media only screen and (max-width: 575px) {
                padding-top: 0;      
            }
        }

        & .add-adoption{
            padding-top: 30px;
            @media only screen and (max-width: 991px) {
                padding-top: 0;      
            }
        }

        & .remove-adoption{
            padding-top: 30px;
            @media only screen and (max-width: 767px) {
                padding-top: 0;      
            }
        }
        
        #adoptions.form-control.is-invalid,#adoptions.form-control.is-valid{
            background-position: center right calc(0.375em + 0.75rem);
        }
    }

}