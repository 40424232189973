.modalPremium.ManagedSurvey {

  & .modal-content {
    border: 0.5px solid #E0E0E0;
    border-radius: 20px;  
    box-shadow: 0px 0px 4px 0px #00000040;  
  }
  
  & .modalPremium-close {
    display: none;
  }
  
  & .button-close img {
    display: none;
  }
  
  & .modal-body {
    padding: 2rem 4rem;
    @media (max-width: 992px) {
      padding: 2rem;
    }

    & div.localPublishingModalContent p img {
      width: 60%;
      @media (max-width: 992px) {
        width: 100%;
      }
    }

    & p {
      font-family: Open Sans;
      text-align: left;
      color: #002147;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.017em;
      @media (max-width: 992px) {
        font-size: 14px;
      }


      & img {
        width: 100%;
        height: auto;
      }
    }

    & p:first-child > strong {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.02em;
      @media (max-width: 576px) {
        /*font-size: 18px;*/
        font-size: 17px;
      }
      @media (min-width: 576px) and (max-width: 992px) {
        font-size: 20px;
      }
    }
    
    & button.button-premium {      
      font-family: Fira Sans;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.017em;
      text-align: center;
      padding: 8px 12px;
      border-radius: 15px;
      @media (min-width: 992px) {
        width: auto;
      }

      &.button-premium-primary {
        float: right;
        color: #002147;
        background-color: #C8DC00;
        border: 2px solid #002147;
        font-weight: 500;
      }
      
      &.button-premium-secondary {
        float: left;
        /*color: #A09DA7;*/
        color: #002147;
        background-color: #FFFFFF;
        /*border: 1px solid #A09DA7;*/
        border: 1px solid #002147;
        font-weight: 400;

        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
          color: #303030;
          background-color: #E3E3E3;
          border: 1px solid #303030;
        }
      }
    }
  }
}

.headerOUP .ManagedSurvey {
  & button.button-premium {
    font-family: Fira Sans;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.017em;
    text-align: center;
    /*padding: 8px 12px;*/
    padding: 6px 8px;
    border-radius: 15px;
    width: auto;
    @media (max-width: 992px) {
      font-size: 12px;
      line-height: 18px;
    }

    &.button-premium-primary {
      float: right;
      color: #002147;
      background-color: #C8DC00;
      border: 2px solid #002147;
      font-weight: 500;
    }
  }
}