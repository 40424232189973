.student-edit{
    background: #f0f8ff;

    & .react-datepicker-wrapper{
        & input{
            padding: 6px 5px;
        }
    }

    & .text-wrap{
        white-space: nowrap !important; 
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
    }

    & .date{
        @media only screen and (min-width: 576px) {
            &.height{
                margin: -6px 0;
            } 
        } 
        @media only screen and (max-width: 575px) {
            margin-top: 24px;    
        } 
    }

}