// Categories styles
.categories {
  a {
    color: $aux-grey700;
    font-size: $font-size-lg;
    text-decoration: none;
  }
  &__withOpacity{
    opacity: .5;    
  }  
  a.active{
    font-weight: bold;
  }
  a.secondaryActive{
    font-weight: bold;
  }
  &__item {
    background: $white;
    border-radius: .5rem ;
  }

  ul,
  li {
    list-style: none;
  }
  &__resource{
    a{
      font-size: $font-size-base;
      color: $clear-blue;
      font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
    }
    a.active{
      color: $orange;
    }
    span{
      font-size: 0.8rem !important;
    }
  }

  span {
    font-size: 0.6rem;
    vertical-align: super;
  }
}

.categories a.secondaryActive + .categories__resource .onlyActive {
  color: $orange;
  font-weight: $font-weight-bold;
}
