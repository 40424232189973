.TeacherActivation {
    h1, h5 {
        text-align: center;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    p {
        text-align: center;
    }
    .error {
        color: #dc3545;
    }
}
