.product-access-control {

  &.customer-user-screen-container {
    min-height: 28rem;
  }

  & .form-group-error {
    color: $red-error-form;

    & input.form-control {
      border-color: $red-error-form;
    }
  }

  & table tbody tr.error-log {
    color: $red-error-form;

    & td span.glyphicon-eye-open {
      cursor: help;
    }
  }
}