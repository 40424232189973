.editAccount {
  margin-bottom: 2rem;
  h1 {
    margin-top: 1rem;
    font-size: 2.7rem;
    color: $aux-grey700;
    font-weight: $font-weight-bold;
  }
  h5 {
    margin-top: 2rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $aux-grey700;
  }
  &__nav {
    .nav-link {
      margin-bottom: 15px;
      padding: 0;
    }
  }
  .school-info {
    margin-bottom: 15px;
  }

  .school-info-title {
    font-weight: bold;
  }

  .change-school-link__nav {
    margin-bottom: 15px;
    .nav-link {
      padding: 0;
    }
  }
}
