// Jumbotron styles

.jumbotronOUP {
  padding: 1.4rem 1rem;
  background: $white;
  border-left: solid 0.4rem $orange;
  border-radius: 0;
  margin-bottom: 0;
  top: 0;
  position: relative;
  width: 100%;
  z-index: 30;

  h2 {
    font-weight: $font-weight-bold;
    color: $aux-grey900;
    width: 100%;
    font-size: 1.2rem;
  }

  a:hover{
    text-decoration: none;
  }

  .lead {
    font-size: 1rem;
    text-transform: uppercase;
  }

  &__img {
    width:100%;
  }

  img {
    width: 100%;
  }
}

.jumbotronOUP.jumbotronOUP--fixed {
  padding: .5rem 1rem;
  position: fixed;
  z-index: 30
}

@media (min-width: 768px) {
  .jumbotronOUP {

    &__img {
      width:60%;
    }

    h2 {
      font-size: 2rem;
    }
    .lead {
      font-size: 1.25rem;
    }
  }
}