.modalPremium {
    & .modal-content {
        border-radius: 20px;
        overflow: hidden;
    }
    &__header {
        background-color: $dark-blue;
        color: white;
        border-bottom: 0;

        & h5 {
            width: 100%;
            font-weight: $font-weight-bold
        }
    }

    &-close {
        right: 16px;
        top: 16px;
        z-index: 2;
        @include media-breakpoint-up(sm) { right: 16px; top: 16px; }
        @include media-breakpoint-up(md) { right: 16px; top: 16px; }
        @include media-breakpoint-up(lg) { right: 18px; top: 18px; }
        @include media-breakpoint-up(xl) { right: 18px; top: 18px; }

        &.button-close img {
            width: 25px;
            height: 25px;
        }
    }
  &__header h5{
    width: 100%;
    font-weight: $font-weight-bold
  }
}
