.container-gpa-modal {
  text-align: center;

  & h5.alert {
    color: $redF45531;
    font-size: 17px;
    font-weight: 400;
    font-family: $font-family-sans-serif;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  & p {
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    font-family: $font-family-button;
    color: $aux-grey600;

    &.header {
      padding-bottom: 20px;
    }
  }  
}