.search-input {
    .glyphicon-search {
        display:block;
        text-align: center;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        position:absolute;
        color: $aux-grey600;
    }
    input {
        border-radius: 1rem;
        padding-left: 2.5rem;
        color: $aux-grey600;
    }
}
