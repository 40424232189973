.create-oup-user-container{
    background: #f0f8ff;

    & form{
        & .search-button{
            padding-top: 30px;
            @media only screen and (max-width: 575px) {
                padding-top: 0;      
            }
        }
    }

}