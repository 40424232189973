.button-product {
  /*background:#4775ae;*/
  background: #4775af;
  box-shadow: 0px 3px 0px #002147;
  border-radius: 6px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  /*padding: 10px 15px;*/
  min-width: 120px;
  text-align: center;
  border: 3px solid transparent;
  transition: 0.5s;
  padding: 8px;
}

.button-product:hover {
  background: #32537a;
  color: #fff;
  opacity: 1;
  transition: 0.5s;
}

.button-blink {
  background-color: #5cc73c;
  color: white;
  border: 3px solid #5cc73c;
}
.button-blink:hover {
  box-shadow: 0px 3px 0px #127b09;  
  background-color: white;
  color: #5cc73c;
}

.button-olb {
  background-color: #ed9c1b;
  color: white;
  border: 3px solid #ed9c1b;
}
.button-olb:hover {
  /*box-shadow:0px 3px 0px #ed9c1b;*/
  box-shadow: 0px 3px 0px #7c5b00;  
  background-color: white;
  color: #ed9c1b;
}

.button-pangea {
  /*background-color: #0294d3;*/
  background-color: #e44121;
  color: white;
  /*border:3px solid #0294d3;*/
  border: 3px solid #e44121;
}
.button-pangea:hover {
  /*box-shadow:0px 3px 0px #0294d3;*/
  box-shadow: 0px 3px 0px #972a15;  
  background-color: white;
  /*color:#0294d3;*/
  color: #e44121;
}

.button-primary:hover,
.button-primary-big:hover,
.button-secondary:hover,
.button-primary-flat:hover,
.form-type-one input:hover[type=submit] {
  background: #32537a;
  color: #fff;
  opacity: 1;
  transition: 0.5s;
}

@media (max-width: 576px) {
  .button-product {
    padding: 10px 15px;
  }
}