.formContainer{
    background: #f0f8ff;


        & .search-button{
            padding-top: 30px;
            @media only screen and (max-width: 575px) {
                padding-top: 0;      
            }
        }

        & .add-adoption{
            padding-top: 30px;
            @media only screen and (max-width: 991px) {
                padding-top: 0;      
            }
        }

        & .remove-adoption{
            padding-top: 30px;
            @media only screen and (max-width: 767px) {
                padding-top: 0;      
            }
        }

        & .validated{
            margin-top: 30px !important;
            padding: 10px 10px !important;
        }
    

}