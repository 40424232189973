// My Library styles
.myLibrarySlider {
  &-container {
    background-color: $aux-dark-grey-background;
    box-shadow: 0px 5px 9px 0px #CACACA;
    position: relative;
  }

  .slick-slider {
    /* margin: 0 -5px;*/

    .slick-track {
      margin-left: 0;
      margin-right: 0;
    }
  }

  h1 {  

    .totalNumber {
      font-size: 0.85rem;
      padding: 0.1rem .8rem;
      background: $clear-blue;
      color: $white;
      border-radius: 0.8rem;
      display: inline-block;
      margin-left: 3rem !important;

      @include media-breakpoint-down(sm) { 
        margin-left: 1rem !important;
      }
    }

    .edit {
      font-size: 0.825rem;
      font-weight: $font-weight-normal;
      transition: 0.4s;
      text-decoration: none;
    }
  }

  .slider {
    &-title {
      font-size: 2rem;
      color: $aux-grey700;
      font-weight: $font-weight-bold;
    }

    &-nav {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
      text-align: right;
      font-size: 0.8em;
      color: $aux-grey700;
    }

  }
  
  .asset-card {
    display: flex;
    border-radius: 0;
    cursor: pointer;
  }

  .item-holder {
    position: relative;
    display: block;
  }

  .demo-image {
    left: 0%;
    top: 0%;
    position: absolute;
  }

  &__img{
    width: 100%; 
    height: 100%;
  }

  .promotion-sticker {
    position: absolute;
    top: 90%;
    left: -5%;
    background-color: $orange-promotion-sticker;
    color: $aux-white;
    font-weight: $font-weight-bold;
    padding: 0.13rem 0.6rem 0.13rem 0.6rem;
    border-radius: 0.5rem 0.13rem 0.5rem 0.13rem;
    font-size: 0.9rem;
  }

}