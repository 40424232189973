h1.coupons_title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    font-style: normal;
    color: #002147;
}
h2.coupons_title {
    font-size: 26px;
    font-weight: 700;
    line-height: 28.8px;
    font-style: normal;
    color: #002147;
}

.custom-checkbox-inline > input {
    display: inline;
}

.custom-checkbox-inline > label {
    display: inline;
    padding-left: 0.6em;
}

button.aslink {
    background: none!important;
    border: none;
    padding: 0;
    /*input has OS specific font-family*/
    cursor: pointer;

    color: #2c64aa;
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
}

button.aslink:hover {
    text-decoration: none;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    color: #0056b3
}

.modal_help_link {
    text-decoration: underline;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
    color: #002147;
    cursor: pointer;
}

select.input_text {
    color: #495057;
    font-size: 16px;
    line-height: 19.2px;
}
