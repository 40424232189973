// Resources styles
.resources {
  max-width: 100%;
  background: $aux-grey200;

  &__new__filter {
      color: $aux-grey700;
  }

  &__new__filter span {
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
  }

  &__pdf {
    padding: 50px 20px 60px;
    iframe {
      border: none;
    }
  }

  &__noViewer {
    background: $aux-black;
    color: $aux-white;

    span {
      font-size: 6.5rem;
      color: $clear-blue;
    }
  }

  &__video {
    padding: 50px 20px 60px;
    &__height {
      height: auto;
      width: 100vh;
    }
  }

  &__audio{
    width: 65vw;
  }

  &__tab__border {
    border-color: $aux-grey600 !important;
  }

  &__schema {
    margin-right: inherit;
  }

  &__catOnly {
    background: $aux-white;
    border-radius: 0.5rem;
  }

  &__listContainer {
    background: $aux-white;
  }

  &__tab {
    width: 100%;
    padding-left: 1.3rem;
    padding-right: 1.3rem;

    &__item {
      width: 50%;
      font-size: 1.2rem;
      text-align: center;
      font-weight: $font-weight-bold;

      a {
        border: none !important;
        color: $aux-grey600;
        margin: 0 .5rem;
      }

      a:hover {
        color: $aux-black;
        border-bottom: solid .4rem $orange !important;
      }

      a.active {
        background: transparent !important;
        border-bottom: solid .4rem $orange !important;
      }
    }
  }

  &__card {
    height: 0;
    overflow: hidden;
    padding-top: 140%;
    background: $aux-white;

    &__inside {
      top: 0;
      left: 0;


      &__legend {
        font-weight: $font-weight-bold;
        font-size: .9rem;
      }

      img {
        object-fit: cover;
        object-position: 50% 50%;
        z-index: 5;
      }

      &__mask {
        background-color: rgba(0, 0, 0, 0);
        z-index: 10;

        button {
          display: none;
          font-size: 0.7rem;
          border-radius: 1rem;
          cursor: pointer;
        }
      }

      &__mask:hover {
        background-color: rgba(0, 0, 0, 0.5);
        border: solid .3rem $dark-blue;

        button {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .resources__col {
    flex: 0 0 calc(100% / 12 * 1.5);
    max-width: calc(100% / 12 * 1.5);
  }
}

.blink {
  max-width: 50%; // isolated only for lg

  &__code span {
    background: $aux-yellow;
    letter-spacing: .8rem;

  }
}
