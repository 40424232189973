.container-gpa {
  & btn:hover,
  & a:hover,
  & a:active {
    text-decoration: none;
    -webkit-transition: color .3s linear;
    -o-transition: color .3s linear;
    transition: color .3s linear;
  }

  & .btn {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    min-width: 160px;
  }

  & .btn:focus,
  & .btn:active.btn:focus {
    outline: none;
  }

  & .btn:active,
  & .btn.active {
    background-image: none;
  }

  & .btn-modal-plus-inverse {
    /*min-width: 150px;*/
    padding-top: 15px;
    padding-bottom: 15px;
    color: $aux-grey600;
    background-color: $aux-white;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: $aux-grey300;
    border-radius: 30px;
  }

  & .btn-modal-plus-inverse .badge {
    color: $aux-white;
    background-color: $aux-grey600;
  }

  & .btn-modal-plus-inverse:hover,
  & .btn-modal-plus-inverse:focus,
  & .btn-modal-plus-inverse.focus,
  & .btn-modal-plus-inverse:active,
  & .btn-modal-plus-inverse.active,
  & .open > .dropdown-toggle.btn-modal-plus-inverse {
    color: $aux-grey600;
    background-color: $black9;
    border-color: $black32;
  }

  & .btn-modal-plus-inverse:active,
  & .btn-modal-plus-inverse.active,
  & .open > .dropdown-toggle.btn-modal-plus-inverse {
    background-image: none;
  }

  & .btn-modal-plus-inverse:hover,
  & .btn-modal-plus-inverse:focus {
    background-color: $aux-white;
    background-position: 0 -15px;
  }

  & .btn-modal-plus-inverse:active,
  & .btn-modal-plus-inverse.active {
    background-color: $aux-white;
    border-color: $aux-white;
  }
  
  & .btn-modal-plus-inverse:hover {
    color: $aux-white;
    background-color: $aux-grey600;
  }

  & .btn-modal-plus-inverse:active {
    color: $aux-white;
    background-color: $black61;
  }

  & .btn-modal-plus-inverse.disabled,
  & .btn-modal-plus-inverse:disabled,
  & .btn-modal-plus-inverse[disabled],
  & fieldset[disabled] .btn-modal-plus-inverse,
  & .btn-modal-plus-inverse.disabled:hover,
  & .btn-modal-plus-inverse[disabled]:hover,
  & fieldset[disabled] .btn-modal-plus-inverse:hover,
  & .btn-modal-plus-inverse.disabled:focus,
  & .btn-modal-plus-inverse[disabled]:focus,
  & fieldset[disabled] .btn-modal-plus-inverse:focus,
  & .btn-modal-plus-inverse.disabled.focus,
  & .btn-modal-plus-inverse[disabled].focus,
  & fieldset[disabled] .btn-modal-plus-inverse.focus,
  & .btn-modal-plus-inverse.disabled:active,
  & .btn-modal-plus-inverse[disabled]:active,
  & fieldset[disabled] .btn-modal-plus-inverse:active,
  & .btn-modal-plus-inverse.disabled.active,
  & .btn-modal-plus-inverse[disabled].active,
  & fieldset[disabled] .btn-modal-plus-inverse.active {
    background-color: $aux-white;
    background-image: none;
    border-color: $aux-grey300;
    opacity: 0.35;
    cursor: default;
  }

  & .btn-premium {
    /*min-width: 150px;*/
    padding-top: 15px;
    padding-bottom: 15px;
    color: $aux-white;
    background-color: $clear-blue;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: transparent;
    border-radius: 30px;
  }

  & .btn-premium .badge {
    color: $clear-blue;
    background-color: $aux-white;
  }

  & .btn-premium:hover,
  & .btn-premium:focus,
  & .btn-premium.focus,
  & .btn-premium:active,
  & .btn-premium.active,
  & .open > .dropdown-toggle.btn-premium {
    color: $aux-white;
    background-color: $blue0271A0;
    border-color: rgba(0, 0, 0, 0);
  }

  & .btn-premium:active,
  & .btn-premium.active,
  & .open > .dropdown-toggle.btn-premium {
    background-image: none;
  }

  & .btn-premium:hover,
  & .btn-premium:focus {
    background-color: $blue15B7FD;
    background-position: 0 -15px;
  }
  & .btn-premium:active,
  & .btn-premium.active {
    background-color: $blue15B7FD;
    border-color: $blue20BAFD;
  }

  & .btn-premium:hover {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
  }
  & .btn-premium:hover:hover,
  & .btn-premium:hover:focus {
    background-color: $blue68BFE4;
    background-position: 0 -15px;
  }

  & .btn-premium:hover:active,
  & .btn-premium:hover.active {
    background-color: $blue68BFE4;
    border-color: $blue71C3E6;
  }

  & .btn-premium:active {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
  }

  & .btn-premium:active:hover,
  & .btn-premium:active:focus {
    background-color: $blue01A1E5;
    background-position: 0 -15px;
  }

  & .btn-premium:active:active,
  & .btn-premium:active.active {
    background-color: $blue01A1E5;
    border-color: $blue01A8EF;
  }

  & .btn-premium:active.disabled,
  & .btn-premium:active:disabled,
  & .btn-premium:active[disabled] {
    background-color: $blue01A1E5;
    background-image: none;
    opacity: 0.35;
    cursor: default;
  }

  & .btn-premium.disabled,
  & .btn-premium[disabled],
  & fieldset[disabled] .btn-premium,
  & .btn-premium.disabled:hover,
  & .btn-premium[disabled]:hover,
  & fieldset[disabled] .btn-premium:hover,
  & .btn-premium.disabled:focus,
  & .btn-premium[disabled]:focus,
  & fieldset[disabled] .btn-premium:focus,
  & .btn-premium.disabled.focus,
  & .btn-premium[disabled].focus,
  & fieldset[disabled] .btn-premium.focus,
  & .btn-premium.disabled:active,
  & .btn-premium[disabled]:active,
  & fieldset[disabled] .btn-premium:active,
  & .btn-premium.disabled.active,
  & .btn-premium[disabled].active,
  & fieldset[disabled] .btn-premium.active {
    background-color: $clear-blue;
    border-color: transparent;
    opacity: 0.35;
    cursor: default;
  }

  & .btn-premium.disabled,
  & .btn-premium:disabled,
  & .btn-premium[disabled] {
    background-color: $blue15B7FD;
    background-image: none;
    opacity: 0.35;
    cursor: default;
  }
 
  & .btn-premium:hover.disabled,
  & .btn-premium:hover:disabled,
  & .btn-premium:hover[disabled] {
    background-color: $blue68BFE4;
    background-image: none;
    opacity: 0.35;
    cursor: default;
  }  
}