.thumbs {

  .thumb {
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 500;

    img {
      width: 20px;
    }

    &-active {
      color: #3a7ad6;
    }

    &-inactive {
      color: #bfbfbf;
    }
  }
}
