.coupons-modal button.coupons-modal-lostaccess {
    width: 100%;
    min-width: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    padding: 5px 5px 0;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    .coupons-modal .form-control {
        font-size: 14px;
    }
}

.coupons-modal input#forgot_email {
    font-size: 0.9rem;
}
