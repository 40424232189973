.sons {

  ul {
    align-items: center;
    padding: 0 0.5rem;
    border: none !important;
  }

  &__tab {
    font-weight: $font-weight-bold;
    font-size: 0.8rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    border: none !important;
    
    cursor: pointer;
    &.active {
      border-bottom: solid .2rem $aux-black !important;
    }
  }

  button {
    padding: 0 1rem;
    border-radius: 1.5rem;
    border: none;
    font-size: 0.9rem;
    height: 1.5rem;
    outline: none !important;
    box-shadow: none !important;
  }

  &__pane {
    border: solid 0.1rem $aux-grey300;
    border-radius: 0.2rem; 
    h5, h6{
      font-weight: $font-weight-bold;
      color: $aux-grey700;
    }
  }

  &__inputUser {
    background-image: url('img/user.png'); 
    background-position-y: center;
    background-position-x: 98%;
    background-repeat: no-repeat;  
    background-size: 1.5rem;
    margin-bottom: .1rem !important;
  }

  &__inputUser-disabled {
    background-image: url('img/userdisabled.png'); 
    background-position-y: center;
    background-position-x: 98%;
    background-repeat: no-repeat;  
    background-size: 1.5rem;
    margin-bottom: .1rem !important;
  }

  &__inputDate{
    text-align: center;
    width: 15rem;
    padding-right: 0.75rem !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    &__fb{
      width: 40%;
      margin-top: -3px;
    }
  }

  p{
    font-family: 'PT Serif',Georgia,"Times New Roman",Times,serif;
    font-size: .85rem;
  }

  p.non-stock.card-text {
    padding: 0.5rem;
    background-color: $orange-ghp-nonstock;
    border-radius: 0.4rem;
  }

  &__inputFile{
    background: none !important;
  }

  &__row{
    border-bottom: solid 0.1rem $aux-grey300;
  }

  &__delete {
    font-size: .7rem;
    right: 0;
    top: .2rem;
    color: #dc3545;
  }

  select[name='id_centro_hijo']:disabled {
    background: $aux-grey300;
    cursor: no-drop;
  }

  select[name='level']:disabled {
    background: $aux-grey300;
    cursor: no-drop;
  }

  select[name='subject']:disabled {
    background: $aux-grey300;
    cursor: no-drop;
  }
}

button.sons__searchButton{
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: $aux-grey200;
  border-radius: 0;
  border-color: $aux-grey200;
  border-left: none;
  color: $aux-black;
  height: 2.15rem; 
}

button.sons__searchButton:focus {
  box-shadow: none;
}

