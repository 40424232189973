.color-dark-gray {
    color: #717171;
}

.ct-error h1.title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #002147;
}

.ct-error .description {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /*line-height: 29px;*/
  line-height: 40px;
  color: #666;
}

.ct-error .description p.error-contact {
  font-style: italic;
  font-weight: 700;
}

.ct-error .button-primary {
  font-size: 14px;
  line-height: 17px;
}

/* MBQ COUPONS-PRODUCT-PURCHASE */
div.banner-success {
  display: block;
  position: relative;
  padding: 1rem 4rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #212121;
  font-size: 1.5rem;
  /*background-color: #9ff2a6;*/
  background-color: #a1d0a5;
  border-color: #badbcc;
  opacity: 0.8;
}
img.success-icon {
  width: 2.5rem;
}
.success-text {
  padding: 0rem 1rem;
  vertical-align: middle;
}

@media (max-width: 992px) {
  .ct-error h1.title {
    font-size: 26px;
    line-height: 31px;
  }  
  .ct-error h1.title .title-underlined strong {
    box-shadow: inset -1px -32px 0px -22px #8cabd1;
  }
  .ct-error .description {
    font-size: 22px;
    line-height: 26px;
  }
  div.banner-success {
    /*padding: 1rem 6rem;*/
    padding: 1rem 4rem;
  }
}

@media (max-width: 576px) {
  .ct-error .description {
    font-size: 20px;
    line-height: 24px;
  }

  div.banner-success {
    padding: 1rem .2rem;
    /*font-size: 1rem;*/
    font-size: .9rem;
  }
  img.success-icon {
    width: 2rem;
  }
  .success-text {
    padding: 0rem .5rem;
  }
}