.discount-ball {
  position: absolute;
  /*right: 0;*/
  right: 5px;
  margin: 0 auto;
  z-index: 1;
  border-radius: 50%;
	width: 47px;
	height: 47px;
	box-shadow: 2px 2px 5px;
}
.discount-ball.free {
  display: none !important;
  bottom: 0;
  background-color: #4ecb71;  
  border: solid 1px #127b09;  
}
.discount-ball.unpaid {
  /*bottom: .5rem;*/
  bottom: 0;
  background-color: #f32323;
  border: solid 1px #b11919;
} 
.discount-ball > div {
  color: #ffffff;
	font-weight: 800;
	font-size: inherit;
	align-content: center;
}

@media (max-width: 576px) {
  .discount-ball {    
    right: .5rem;    
    width: 5rem;
    height: 5rem;
  }
}