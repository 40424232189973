// Categories styles
.unitsOUP{
  li{
    width: 100%;
  }
  a{
    background: none !important;
    color: $aux-grey700;
    width: 100%;
    font-size: $font-size-sm;    
    &:hover {
      background: lighten( $orange, 15% ) !important;
    }
  }
  a.active{
    background: $orange !important;
  }  
}
