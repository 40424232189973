.selectlanguage {
  &__dropdown__toggle {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    color: $aux-white;
    background-color: $dark-blue;
  }

  &__dropdown {
    font-size: 16px;
    text-align: left;
    margin-top: -2px;
    background-color: $aux-white;
    border-top-color: transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    a:hover,
    a:focus,
    button:focus,
    button:hover {
      background: $white;
      opacity: 0.65;
    }

    &__item {
      color: $aux-greyBlue;
      background-color: $white;
      text-align: left;
      padding: 12px;
      font-size: 0.8rem;
      transition: 0.4s;
      cursor: pointer;

      &:hover {
          color: $aux-grey500;
      }

    }

    &__item:last-child {
      border-bottom: 0;
    }
  }
}
