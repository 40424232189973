.container-gpa {
  font-family: $font-family-button;
  font-size: 16px;
  line-height: 1.42857143;
  color: $aux-grey600;
  background: $aux-grey200;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: transparent solid 0.1px;
  min-height: inherit;

  & .container {
    @include media-breakpoint-up(md) { width: 1054px; }
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-top:  2rem; 
  }
 
  & a {
    text-decoration: none;
  }

  & p {
    margin: 0 0 11px;
    display: block !important;
  }

  & h6, & .h6 {
    font-size: 14px;
  }

  & h4, & .h4, & h5, & .h5, & h6, & .h6 {
    margin-top: 11px;
    margin-bottom: 11px;
  }

  & h1, & h2, & h3, & h4, & h5, & h6, & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: $aux-grey700;
    display: block !important;
  }

  & .well {
    border-color: $aux-grey600;
    -webkit-box-shadow: inset 0 1px 3px rgba(0,0,0,.05),0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 3px rgba(0,0,0,.05),0 1px 0 rgba(255,255,255,.1);
  }

  & .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: $black4;
    border: 1px solid $black11;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  }

  & hr {
    margin-top: 22px;
    margin-bottom: 22px;
    border: 0;
    border-top: 1px solid $aux-grey300;
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  & .adopcion-recursos {
    & h3 {
      padding-bottom: 5px;
      font-size: 26px;
      color: $aux-grey900;
      border-bottom: 2px solid $aux-grey600;
    }

    & .row-sm-center {
      @include media-breakpoint-down(sm) { text-align: center; }
    }

    & .well.well-cards-material {
      @include media-breakpoint-up(md) { padding: 70px 85px; }
      background-color: $aux-white;
      border-color: transparent;
      border-radius: 5px;
      -webkit-box-shadow: 0 2px 2px 0 $black17;
      box-shadow: 0 2px 2px 0 $black17;

      & .text-label {
        font-family: $font-family-sans-serif;
        font-size: 16px;
        color: $aux-grey900;
      }

      & p {
        font-size: 14px;

        &.description {
          font-family: $font-family-sans-serif;
          color: $aux-grey900;
        }

        &.small {
          font-size: 12px;
          line-height: 15px;
        }
      }

      & .pedir-material {
        & .title-table {
          font-family: $font-family-sans-serif;
          @include media-breakpoint-down(md) { font-size: 16px; }
          @include media-breakpoint-up(md) { font-size: 19px; }
          color: $aux-grey900;
        }

        & .row.form-group.row-shadow {
          background-color: $aux-grey200;
          border-radius: 6px;
        }

        & .form-group {
          & .control-label {
            padding-top: 14px;
            font-size: 16px;
            text-align: left;
            display: inline-block;
          }
  
          & .form-control {
            margin-right: 0;
            display: inline-block;
          }

          & .btn-add-delete.plus {
            margin: 0.5rem;
          }

          & .btn-add-delete.minus {
            margin: 0.5rem;
          }

          & .btn-add-delete {
            width: 28px;
            height: 28px;
            padding: 0;
            font-size: 24px;
            font-weight: 100;
            line-height: 24px;
            color: $aux-white;
            background-color: $blue0394D3;
            border-radius: 5px;
            display: inline-block;
          }

          /*&:nth-child(even) {
            background-color: $aux-grey200;
            border-radius: 6px;
          }*/
        }
      }

      & .form-horizontal .checkbox {
        padding-right: 11px;
        margin-left: 11px;
      }

      & .form-group {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0;
        font-family: $font-family-sans-serif;
        color: $aux-grey900;

        & .control-label {
          font-size: 17px;
          text-align: right;
          @include media-breakpoint-up(md) { line-height: 3; }
        }

        & p.text-number {
          font-weight: 700;
          color: $aux-grey600;
          padding-top: 8px;
        }

        & .text-number {
          font-weight: 700;
          color: $aux-grey600;
        }

        & .centered {
          font-size: 0;
          text-align: center;
        }

        & .centered > div {
          display: inline-block;
          float: none;
          font-size: 13px;
          text-align: left;
        }

        & .form-control {
          width: 85px;
          height: 50px;
          margin-right: 5px;
          font-size: 27px;
          font-weight: 700;
          color: $black71;
          text-align: right;
          background-color: $aux-white;
          border: 1px solid $aux-grey600;
          border-radius: 10px;
        }        
      }

      & .text-number {
        padding-left: 5px;
        font-family: $font-family-sans-serif;
        font-size: 27px;
      }

      & .image-recurso {
        margin: 0;
        border-radius: 5px;
      }

      & .red {
        color: $redF45531;
      }
    }

    & .well.well-cards-adopcion .image-recurso {
      border-radius: 5px;
    }
  }  

  & .form-horizontal {
    & .radio, & .checkbox {
      min-height: 29px;
    }

    & .radio, & .checkbox, & .radio-inline, & .checkbox-inline {
      padding-top: 7px;
      margin-top: 0;
      margin-bottom: 0;
    }

    & .form-group {
      margin-right: -10px;
      margin-left: -10px;
    }
  
    & .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
    }
  }

  & .radio, & .checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .radio label, & .checkbox label {
    min-height: 22px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }

  & label {
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: $black96;
    letter-spacing: .4px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
  }

  & .form-control {
    border: none;
    -webkit-box-shadow: inset 0 1px 0 0 rgba(0,0,0,.1);
    box-shadow: inset 0 1px 0 0 rgba(0,0,0,.1);
    font-family: $font-family-sans-serif;
  }

  & .img-responsive {
    margin-right: auto;
    margin-left: auto;
  }

  & .img-responsive, & .thumbnail > img, & .thumbnail a > img, & .carousel-inner > .item > img, & .carousel-inner > .item > a > img {
    @include media-breakpoint-down(md) { display: inline-block; }
    @include media-breakpoint-up(md) { display: block; }
    max-width: 100%;
    height: auto;
  }

  & img {
    vertical-align: middle;
    border: 0;
  }
}

.gpa__modal {
  & .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    display: none;
    overflow: hidden;
  }

  & .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    /*width: 480px;*/
    pointer-events: auto;
    background-color: $aux-white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    float: left;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }

  & .modal-header {
    border-bottom: 0;

    & button.close {
      color: $aux-grey900;
      filter: alpha(opacity=80);
      border: 1px solid $aux-grey600;
      border-radius: 35px;
      opacity: .8;
      cursor: pointer;
      background: transparent;
      padding: 6px 9px;
      margin-top: -5px;
      margin-right: -5px;
      font-size: 13px;
    }

    & button.close:hover, & button.close:focus {
      filter: alpha(opacity=50);
      opacity: .5;
    }

    & small, & .small {
      font-size: 15px;
      font-weight: 400;
      font-family: $font-family-button;
      color: $aux-grey600;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 1.1;
    }
  }

  & .modal-body {
    padding: 15px;
    padding-bottom: 20px;
    padding-top: 0;
    text-align: center;
    font-family: $font-family-button;
    font-size: 15px;
    color: $aux-grey600;
  }
}