.breaking-news {
    h1 {
        font-size: 2rem;
        color: $aux-grey700;
        font-weight: $font-weight-bold;
    }

    &-item {
        font-size: 0.8rem;
        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
        background-color: $aux-grey-background;
        box-shadow: 0px 5px 9px 0px #CACACA;
        color: $aux-grey700;
        cursor: pointer;

        & img {
            object-fit: cover;
        }

        &-title {
            font-weight: bold;
            line-height: 1.3rem;
            height: 3.9rem;
            overflow: hidden;
        }
    }
}
