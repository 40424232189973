span.product-whatispangea {
    display: inline-block;
    vertical-align: bottom;
}
a.product-whatispangea {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #666666;
}
.ct-product {
    height: 229px;
	width: 450px !important;
	border-radius: 15px;
}
.ct-product.no-promotion {
    background-color: white;
}
.ct-product.promotion {
    background-color: #F2EEDA;
    border: 2px solid #F3D74B;
}
.row-promotion-label {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #9B1827;
}
.product-cover {
    height: 100%;
    width: 100%;
}
.product-name {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #666666;
}
.product-name .truncate {
    cursor: pointer;
}
.product-price {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    border: 0;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 50px;
    background-color: #F3D74B;
    margin: 5px 0;
}
.product-price .pvp {
    color: #ff0000;
    text-decoration: line-through;
}
.product-promotion {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.row-license {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}
.product-label-small {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}
.product-license {
    width: 82%;
}
span.bt-copy-license {
    background: rgba(217, 217, 217, 0.58);
    height: 40px;
    width: 40px;
    padding: 2px;
    display: flex;
    align-items: center;
    text-align: center;
}
img.w-20px {
    width: 20px;
}
.product-bt-purchase {
    font-size: 14px !important;
    line-height: 17px !important;
    padding: 8px !important;
}

.coupons-product-new {
    border: 3px solid #ffe12d;
    position: relative;
}

.coupons-product-new-label {
    position: absolute;
    height: 30px;
    width: 70px;
    border: 1px solid black;
    color: black;
    text-align: center;
    background-color: #ffe12d;
    border-radius: 22px;
    display:flex;
    z-index: auto;
    font-size: 14px;
    top: -16px;
    right: 20px;
}

@media (min-width:768px) {
    .ct-product .col-data {
        height: 100%;
        padding-left: 0 !important;
        padding-right: 5px !important;
    }
    .ct-product .ct-data row {
        padding: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }    
    .ct-product .ct-data col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }    
    .ct-product .ct-data {
        height: 100%;
    }
    .ct-product .ct-data .product-name {
        /*height: 30%;*/
        padding-right: 5px !important;
    }
    .ct-product.no-promotion .ct-data .product-name {
        height: 30%;
        align-items: flex-start !important;
    }
    .ct-product.promotion .ct-data .product-name {
        height: 25%;
        /*height: 40%;*/
        align-items: center !important;
    }
    .ct-product .ct-data .row-price {
        height: 20%;
    }
    .ct-product .ct-data .row-promotion-label {
        height: 15%;
        /*height: 10%;*/
    }
    .ct-product .ct-data .row-license {
        height: 25%;
    }
    .ct-product .ct-data .row-expires {
        height: 15%;
    }
    .ct-product .ct-data .row-expires-40 {
        height: 40%;
    }
    .ct-product .ct-data .row-promotion {
        height: 15%;
    }
    .ct-product .ct-data .row-button {
        padding-right: 5px !important;
        padding-bottom: 5px !important;
        height: 25%;
    }
    /*.ct-product.no-promotion .ct-data .row-button {
        height: 30%;
    }
    .ct-product.promotion .ct-data .row-button {
        height: 25%;        
    }*/
}

@media (max-width: 576px) {
    .ct-product {
        height: 100%;
        width: 100% !important;
        border-radius: 15px;
    }
    .ct-product .ct-data .row-promotion-label,
    .ct-product .ct-data .product-name,
    .ct-product .ct-data .row-price,
    .ct-product .ct-data .row-promotion,
    .ct-product .ct-data .row-license,    
    .ct-product .ct-data .row-expires,
    .ct-product .ct-data .row-expires-40,
    .ct-product .ct-data .row-button {
        text-align: center !important;
    }
    /*.product-price {
        font-size: .9rem;
    }*/
    .product-license {
        width: 72%;
    }    
    .product-bt-purchase {
        padding: 10px 15px;
    }
}
