.infobox {
    height: 225px;
    display: flex;
}

.infobox-header {
    margin: 0 auto;
    z-index: 1;
    border-radius: 50%;
    background-color: #8CABD1;
    width: 65px;
    height: 65px;
}

.infobox-header > div {
    color: #FFFFFF;
    font-weight: 800;
    font-size: 32px;
}

.infobox-card {
    position: absolute;
    top: 35px;
    width: calc(100% - 30px);
    height: 193px;
    background-color: white;
    border-radius: 22px;
    padding-top: 45px;
}

.infobox-body {
    text-align: center;
    width: 100%;
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    height: 90px;
    padding: 0 1rem;
}

.infobox-link {
    height: 53px;
    width: 100%;
    display: flex;

    text-align: center;
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #002147;

    cursor: pointer;
}
