// Resources List styles
.resourceList {
  color: $aux-grey700;

  &__LightBox {
    &__Name {
      color: $aux-grey200;
      font-weight: $font-weight-bold;
    }

    &__Info {
      color: $aux-grey200;
      font-weight: $font-weight-bold;
      width: calc(60% - 2rem);

      span {
        font-weight: $font-weight-normal;
        color: $aux-white;
      }
    }

    &__Download {
      width: calc(40% - 2rem);
    }
  }


  h2 {
    border-bottom: solid 0.4rem $aux-grey700;
    font-weight: $font-weight-bold;
  }

  h3 {
    font-weight: $font-weight-bold;
  }

  &__book__icon {
    color: $aux-grey300;
    font-size: 2rem;
  }

  &__TRitem {
    border-bottom: solid 1px $aux-grey300;
  }

  &__TRitem:last-of-type {
    border-bottom: none;
  }

  &__title {
    button {
      background: none;
      color: $aux-grey700;
      border: none;
      font-size: $font-size-sm;
      padding: 0;
      cursor: pointer;
      outline: inherit;
    }

    a {
      font-size: $font-size-sm !important;
      color: $aux-grey700 !important;
      text-decoration: none;
    }

    font-size: $font-size-sm;

    &__unit {
      font-size: .65rem;
      color: $info
    }

    &__new {
      font-size: .75rem;
      font-weight: bold;
      color: green;
    }

    &__updated {
      font-size: .65rem;
    }
  }

  a {
    color: $clear-blue;
    font-size: $font-size-lg;
  }

  &__THicon {
    vertical-align: middle !important;
    width: 5%;
    img {
      height: 3rem;
    }
  }

  &__TDitem {
    vertical-align: middle !important;
    width: 75%;
  }

  &__TDMulti {
    vertical-align: middle !important;
    button {
      background: none;
      color: $clear-blue;
      border: none;
      font-size: $font-size-lg;
      padding: 0;
      cursor: pointer;
      outline: inherit;
    }

    width: 5%;
  }

  &__TDLast {
    width: 5%;
  }

  .favourite-icon {
    color: $clear-blue;
    font-size: $font-size-lg;
    cursor: pointer;
    margin-top: 3px;
  }
}

.ril__captionContent {
  width: 100% !important;
}
