.menu-panel {
    min-height: 28rem;
    background-color: $aux-grey300;

    &-button-container {
        background-color: $aux-grey200;
    }

    &-button {
        height: 150px;
        width: 200px;
    }
}