.change-password {
    margin-bottom: 2rem;
    h1 {
        margin-top: 1rem;
        font-size: 2.7rem;
        color: $aux-grey700;
        font-weight: $font-weight-bold;
    }
    h5 {
        margin-top: 2rem;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        color: $aux-grey700;
    }
}
