// Boostrap scss files

@import "custom";
@import "glyphicons";
/*
@import "~font-awesome/scss/variables";
@import "~font-awesome/scss/mixins";
@import "~font-awesome/scss/path";
@import "~font-awesome/scss/core";
@import "~font-awesome/scss/larger";
@import "~font-awesome/scss/fixed-width";
@import "~font-awesome/scss/list";
@import "~font-awesome/scss/bordered-pulled";
@import "~font-awesome/scss/animated";
@import "~font-awesome/scss/rotated-flipped";
@import "~font-awesome/scss/stacked";
@import "~font-awesome/scss/icons";
@import "~font-awesome/scss/screen-reader";
*/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


// Import only th Boostrap components that we are using
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";

// New sass files

@import "../components/Header/styles";
@import "../components/MyLibrary/styles";
@import "../components/MyLibrarySlider/styles";
@import "../components/Jumbotron/styles";
@import "../components/Resources/styles";
@import "../components/Categories/styles";
@import "../components/Units/styles";
@import "../components/ResourceList/styles";
@import "../components/Tabs/styles";
@import "../components/PlusPremium/styles";
@import "../components/Modal/styles";
@import "../components/ChangeSchoolFinder/styles";
@import "../components/ChangeSchoolCenterData/styles";
@import "../components/ChangeSchoolError/styles";
@import "../components/ChangeSchoolSuccess/styles";
@import "../components/EditLibrary/styles";
@import "../components/GratisHP/styles";
@import "../components/Sons/styles";
@import "../components/HPSuccess/styles";
@import "../components/HPError/styles";
@import "../components/News/styles";
@import "../components/ButtonPremium/styles";
@import "../components/EditAccount/styles.scss";
@import "../components/Banner/styles";
@import "../components/Notifications/styles";
@import "../components/NotificationChangedAdoptions/styles";
@import "../components/NotificationGpa/styles";
@import "../components/GpaResponsableModal/styles";
@import "../components/TeacherRegistration/styles";
@import "../components/TeacherActivation/styles";
@import "../components/Modal2Buttons/styles";
@import "../components/ModalSchoolByPostalCode/styles";
@import "../components/ModalAccept/styles";
@import "../components/ChangePassword/styles";
@import "../components/UserAdministration/styles";
@import "../components/ActivationCode/styles";
@import "../components/ActivationCodeForm/styles";
@import "../components/ModalAccept/styles";
@import "../components/ChangePassword/styles";
@import "../components/UserAdministration/styles";
@import "../components/SelectLanguage/styles";
@import "../components/Footer/styles";
@import "../components/GpaOrders/styles";
@import "../components/GpaOrder/styles";
@import "../components/GpaNavbar/styles";
@import "../components/GpaOrderButton/styles";
@import "../components/GpaOrderCardDetail/styles";
@import "../components/GpaOrderCardItem/styles";
@import "../components/GpaTitleView/styles";
@import "../components/GpaModal/styles";
@import "../components/Links/styles";
@import "../components/PasswordForgot/styles";
@import "../components/PasswordReset/styles";
@import "../components/SearchInput/styles";
@import "../components/ProfessionalDevelopment/styles";
@import "../components/ResourceNotifications/styles";
@import "../components/BreakingNews/styles";
@import "../components/SigninForm/styles";
@import "../components/PublicHomePage/styles";
@import "../components/ThirdPartiesError/styles";
@import "../components/DigitalContent/styles";
@import "../components/TopBanners/styles";
@import "../components/PDNews/styles";
@import "../components/ModalLibraryGuidance/styles";
@import "../components/BlinkCptLicenceForm/styles";
@import "../components/BlinkCptLicenceContent/styles";
@import "../components/OAuth2Error/styles";
@import "../components/Thumbs/styles";
@import "../components/Buttons/styles";
@import "../components/Students/styles";
@import "../components/CookieBanner/styles";
@import "../components/PromotionHome/styles";
@import "../components/PromotionTitle/styles";
@import "../components/TitleBanner/styles";
@import "../components/WarningBanner/styles";
@import "../components/Administrator/UserSearchAdministrator/styles";
@import "../components/Administrator/CreateFictitiousUser/styles";
@import "../components/Administrator/CreateOUPUser/styles";
@import "../components/Administrator/CustomerUserEdit/formStyle";
@import "../components/Administrator/StudentEdit/styles";
@import "../components/Administrator/InvoicePanel/styles";
@import "../components/Administrator/MenuPanel/styles";
@import "../components/Administrator/ProductAccessControl/styles";
@import "../components/TitleCarousel/styles";
@import "../components/MyDelegates/styles";
@import "../components/PDCourses/styles";
@import "../components/ProductViewer/styles";
@import "../components/Polls/ManagedSurvey/styles";

// Other
@import "breadcrumb";
@import "_spinners";
@import "_layout";
